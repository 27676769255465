import React,{useState, useEffect} from 'react'; 
import {FaSyncAlt, FaExternalLinkSquareAlt} from 'react-icons/fa'
import axios from '../node_modules/axios'; 
import {Nabvar} from './component/Navbar';  
import { Chart } from "react-google-charts";
import { ToastContainer, toast } from 'react-toastify';
import {ModalCarga, Toast} from './component/modalCarga'; 
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal'; 
import './App.css';  
import {formatNumber} from './commonFunctions';
import { formatDate} from './formatDate';
import customStyles from './component/modalCustomStyles';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";  
import {   ThreeDots } from  'react-loader-spinner'
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from 'react-data-table-component';
import tableCustomStyles from './tableCustomStyles';
 
 
function Ordenes(props){
  
    const [lista, setLista] =  useState([]);   
    const [listados, setListaDos] =  useState([]);   
    const [listatres, setListaTres] =  useState([]);   
    const [listaestados, setListaEstados] =  useState([]);     
    const [chartData, setChartData] =  useState([]);    
    const [listaProveedores1, setListaProveedores1] = useState([]); 
    const [listaProveedores, setListaProveedores] = useState([]); 
    const [listaEstaciones1, setListaEstaciones1] = useState([]);
    const [listaEstaciones, setListaEstaciones] = useState([]);
    const [registros, setRegistros] =  useState([]);     
    const [totales, setTotales] =  useState([]);     
    const [selectedProveedores, setSelectedProveedores] = useState([]);
    const [selectedEstaciones, setSelectedEstaciones] = useState([]);
    
    

    const options = {
        chart: {},
        chartArea: { backgroundColor: 'transparent' },
        bar: {color: '#76A7FA'},
        backgroundColor: 'transparent',
        strokeColor:'#76A7FA', 
        fontSize:14,
        annotations: {
            textStyle: {
              fontName: 'Times-Roman',
              fontSize: 12
            }
        },
        hAxis: { title: 'Reporte de compras',fontSize: 12 },
      };
     
 

    useEffect(()=> {
        getOrdenes(); 
        getAllProveedores();
        getAllEstaciones();
    }, [])

    async function getAllProveedores(){
         
		var id = "getAllProveedores";
		//openModalLoad();
		const rese = await axios.get(process.env.REACT_APP_API_URL+'?id='+id+'&dptoid='+props.dptoid);  
		//closeModalLoad();
		setListaProveedores(rese.data);  
		setListaProveedores1(rese.data);
	}

    async function getAllEstaciones() {
        var id = "getAllEstaciones";
        //openModalLoad();
        const response = await axios.get(process.env.REACT_APP_API_URL + '?id=' + id);
        setListaEstaciones(response.data);
        setListaEstaciones1(response.data);
        //console.log(response.data);
    }
    
    

  
  
    const [modalIsOpenLoad, setIsOpenLoad] = React.useState(false);
    function notify(message){
        toast(message);
    }
    function openModalLoad() { 
    setIsOpenLoad(true); 
     }  
   
     function closeModalLoad() { 
    setIsOpenLoad(false); 
     }

 
  
     async function getOrdenes() {    
        var id = "GetOrdenesPagadas";   
        var rzonsocial = props.rzonsocial;
        setLista([]);
        setListaDos([]);
        setTotales([]);
        setRegistros([]);
        var date = document.getElementById("input-fecha").value; 
        var date1 = document.getElementById("input-fecha1").value; 
        var proveedor = selectedProveedores.join(',');
        var estacion = selectedEstaciones.join(',');
        openModalLoad();
        const res = await axios.get(process.env.REACT_APP_API_URL + '?id=' + id + '&userid=' + props.userid + '&date=' + date + '&date1=' + date1 + '&dptoid=' + props.dptoid + '&rzonsocial=' + rzonsocial + '&tipousuario=' + props.tipo + '&proveedor=' + proveedor + '&estacion=' + estacion);
        closeModalLoad();  
        var table = document.getElementById('productstable');
        if (res.data.length >= 1) {
            setLista(res.data);  
            setListaDos(res.data);
            var listaordenes = res.data;
            var listaestados = res.data;
            var listadeproveedores = listaordenes.filter((ele, ind) => ind == listaordenes.findIndex(elem => elem.proveedor == ele.proveedor))
            var listadeestados = listaestados.filter((ele, ind) => ind == listaestados.findIndex(elem => elem.estadoorden == ele.estadoorden))
            // setListaTres(listadeproveedores);
            setListaEstados(listadeestados); 
            let empAge = []; 
            let i = 1;
            let tot = 0;
            empAge[0] = ["Orden de compra", "Importe", { role: 'style' }];
            for (const dataObj of res.data) {
                tot = tot + parseFloat(dataObj.total);
                empAge[i] = [dataObj.identificador, parseFloat(dataObj.total), 'color: ' + props.defaultColor];
                i++;
            }
            setTotales(tot);
            setRegistros(res.data.length);
            setChartData(empAge);  
            // console.log(data);
            try {
                table.removeAttribute("hidden");
            } catch (Exception) {}
        } else {
            table.setAttribute("hidden", true);
        }
        let item = res.data;
        let total = 0;
        for (let element of item) {
            total = parseFloat(total) + parseFloat(element.total)
        }
        document.getElementById('TotalL').innerHTML = '$' + total.toLocaleString('en-US');
    }
    

    
    
  
    async function filtrarporProveedor(){
        let nombre = document.getElementById("slcProveedor").value;
        nombre = "SANDY WENDOLINE FLORES FLORES";
        let filtrado = listados.filter(item => item.proveedor == nombre);
        console.log(filtrado);
        let empAge = []; 
        let i = 1;
        let tot = 0;
        empAge[0] = ["Orden de compra","Importe"];
        for (const dataObj of filtrado) { 
            tot = tot + parseFloat(dataObj.total);
            empAge[i] = [dataObj.identificador, parseFloat(dataObj.total)];
            i++;
          }

          setChartData(empAge); 
          setTotales(tot);
          setRegistros((empAge.length)-1);
        setLista(filtrado);
        let item = filtrado;
        let total = 0;
        for(let element of item){
            total = parseFloat(total) + parseFloat(element.total)
        }
        document.getElementById('TotalL').innerHTML= '$'+total.toLocaleString('en-US');
    }


    
    async function filtrarporEstado(){
        let estado = document.getElementById("slcEstado").value;
        let filtrado = listados.filter(item => item.estadoorden == estado);
        console.log(filtrado);
        let empAge = []; 
        let i = 1;
        let tot = 0;
        empAge[0] = ["Orden de compra","Importe"];
        for (const dataObj of filtrado) { 
            tot = tot + parseFloat(dataObj.total);
            empAge[i] = [dataObj.identificador, parseFloat(dataObj.total)];
            i++;
          }

          setTotales(tot);
          setRegistros((empAge.length)-1);

          setChartData(empAge); 
        console.log(filtrado);
        setLista(filtrado);
        let item = filtrado;
        let total = 0;
        for(let element of item){
            total = parseFloat(total) + parseFloat(element.total)
        }
        document.getElementById('TotalL').innerHTML= '$'+total.toLocaleString('en-US');
    }

    function handleKeyPress  (event) {
		if(event.key == 'Enter'){ 
			getOrdenes();
            document.getElementById("buscar-pro").style.display = "none";
		}
	}

    function filtrarPorProveedor(e){
        var selProveedores = document.getElementById("buscar-pro");
        var result = listaProveedores1.filter(item => item.proveedor.toLowerCase().includes(e.target.value.toLowerCase()))
        //console.log(result);
         setListaProveedores(result);
        if(e.target.value.toLowerCase() == "" || result.length < 1){
         selProveedores.style.display = "none";
        }else{
         selProveedores.style.display = "block";
         selProveedores.selectedIndex = -1;
         selProveedores.size = 5;
         var my_index = 100;
         selProveedores.style.zIndex = my_index++;
        }
     }

     function filtrarPorEstacion(e) {
        var selEstaciones = document.getElementById("buscar-est");
        var result = listaEstaciones1.filter(item => {
            // Check if item.estacion is a string and not null or undefined
            if (typeof item.estacion === 'string' && item.estacion !== null) {
                return item.estacion.toLowerCase().includes(e.target.value.toLowerCase());
            }
            return false; // Exclude items with null or non-string values for estacion
        });
        console.log(result);
        setListaEstaciones(result);
        if(e.target.value.toLowerCase() == "" || result.length < 1){
            selEstaciones.style.display = "none";
        } else {
            selEstaciones.style.display = "block";
            selEstaciones.selectedIndex = -1;
            selEstaciones.size = 5;
            var my_index = 100;
            selEstaciones.style.zIndex = my_index++;
        }
    }
    
    
 
     function proveedorSelected(){
         document.getElementById("input-proveedor").value = document.getElementById("buscar-pro").value;
         document.getElementById("buscar-pro").style.display = "none";
         getOrdenes();
     }
    function actualizarReporteCompras(ordenid){ 
        var Factura = document.getElementById("Factura"+ordenid).value;
         var FechaPago = document.getElementById("FechaPago"+ordenid).value;
        var inputObservacionesSeguimiento = document.getElementById("inputObservacionesSeguimiento"+ordenid).value;
           
        let topost = new FormData();
                topost.append("id", "actualizarReporteCompras"); //actualizar orden de pago
                topost.append("ordenid", ordenid);
                topost.append("Factura", Factura); 
                topost.append("FechaPago", FechaPago);
                topost.append("inputObservacionesSeguimiento", inputObservacionesSeguimiento);
                
                
            fetch(process.env.REACT_APP_API_URL,{
                method: 'POST',
                mode: 'cors',
                body: topost
            })
            .then(response => response.text())
            .then((data) => {
                notify(data.trim());
                getOrdenes();
            });

            
    
        
}

function formatDateFilter(date){
    let index = date.search(" ");
    date = date.substring(0, index);
   // date = date.split("-");
    let formatedDate = date[2] +"/"+ date[1] +"/"+ date[0];
    return(date);
  }
  const handleSelectProveedor = (e) => {
    const selectedProveedor = e.target.value;
    if (selectedProveedores.includes(selectedProveedor)) {
        // If the proveedor is already selected, remove it from the array
        setSelectedProveedores(selectedProveedores.filter(proveedor => proveedor !== selectedProveedor));
    } else {
        // If the proveedor is not selected, add it to the array
        setSelectedProveedores([...selectedProveedores, selectedProveedor]);
    }
    document.getElementById("buscar-pro").style.display = "none";
};

const clearSelectedProveedores = () => {
    setSelectedProveedores([]);
    document.getElementById('input-proveedor').value = '';
};

const handleSelectEstacion = (e) => {
    const selectedEstacion = e.target.value;
    if (selectedEstaciones.includes(selectedEstacion)) {
        // If the proveedor is already selected, remove it from the array
        setSelectedEstaciones(selectedEstaciones.filter(estacion => estacion !== selectedEstacion));
    } else {
        // If the proveedor is not selected, add it to the array
        setSelectedEstaciones([...selectedEstaciones, selectedEstacion]);
    }
    document.getElementById("buscar-est").style.display = "none";
};
const clearSelectedEstaciones = () => {
    setSelectedEstaciones([]);
    document.getElementById('input-estacion').value = '';
};
/* 
Columnas tabla
*/
const columns = [
    {
        name: 'Orden', 
        sortable: true, 
        width: "100px",
        cell: (row) => {
            return(
            <td className='id-orden' onClick={() => props.verOrden(row.idrequisicion, row.foliof)}>{row.foliof} <FaExternalLinkSquareAlt style={{color:props.defaultColor}}/></td>
            )
        }
        
    },
    {
        name: 'Proveedor', 
        width: "180px",
        cell: (row) => row.proveedor,
    },
    {
        name: 'Factura', 
        sortable: true, 
        cell: (row) => {
            return (
                <input value={row.factura || ''}
                onChange={(e)=> {
                document.getElementById("Factura"+row.ordencompra).value = e.target.value}}
                id={"Factura"+row.ordencompra}   style={{width:'55px'}}></input>
            )
        }
    },
    {
        name: '',  
        cell: (row) => {
                return (
                    <td hidden>
                    {row.factura} 
                    </td>
                )
            },
            width: "10px",
    },
    {
        name: 'Importe',
        sortable: true, 
        cell: (row) => formatNumber(row.total),
        width: "100px",
    },
    {
        name: 'Concepto', 
        width: "250px",
        cell: (row) => {
            return (
                <td onClick={() => props.verReq(row.idrequisicion)}>{ (!row.producto == "") ? row.producto +' ('+row.idrequisicion+')' : row.identificador +' ('+row.idrequisicion+')'} <FaExternalLinkSquareAlt style={{color:props.defaultColor}}/></td>
            )
        }
    },
    {
        name: 'Fecha Orden', 
        sortable: true, 
        cell: (row) => formatDateFilter(row.fechacaptura),
    },
    {
        name: 'Fecha Pago',
        width: '125px',
        cell: (row) => {
            return (
                <td style={{width:'100px'}}><input id={"FechaPago"+row.ordencompra}  defaultValue={row.fechapago} type="date"  /></td>
            )
        }
         
    },
    {
        name: '',  
        cell: (row) => {
                return (
                    <td hidden>
                    {row.fechapago} 
                    </td>
                )
            },
            width: "10px",
    },
    {
        name: 'Estado Orden',
        cell: (row) => row.estador 
         
    },
    {
        name: 'Estado Requisicion',
        cell: (row) => {
            return (
                <td>
                                
                    <label>{row.requisicion}</label>

                </td>
            )
        }
    },
    {
        name: 'Observaciones',
        cell: (row) => {
            return (
                <td><input id={"inputObservacionesSeguimiento"+row.ordencompra}   defaultValue={row.observacionseguimiento}  type="text"  class="width-100" onChange={() => function(){}} ></input></td>
            )
        }
    },
    {
        name: '',  
        cell: (row) => {
                return (
                    <td hidden>
                    {row.observacionseguimiento} 
                    </td>
                )
            },
            width: "10px",
    },
    {
        name: 'Actualizar',
        cell: (row) => {
            return (
                <td><button id="btn-actualizar" className="btn btn-outline-success btn-sm" onClick={() => actualizarReporteCompras(row.ordencompra)}>Actualizar</button></td>
            )
        }
    },
];


 
  

  
    return(
        <div className="container "> 
            <Nabvar  defaultColor={props.defaultColor} titulo="Reporte de compras" departamento={props.rzonsocial} dptoid={props.dptoid}/> 
           
   
            <div className="row p-3">
                <h3>Reporte de Compras</h3>
            
                <div>
                    <span>Filtrar por fecha &nbsp;</span>
                    <input id='input-fecha' type='date' class="input-filter"></input>
                    <span>&nbsp; &nbsp;</span>
                    <input id='input-fecha1' type='date' class="input-filter"></input>
                    <span>&nbsp; &nbsp;</span>
                    <button class='btn btn-outline-success btn-sm' onClick={() => getOrdenes()} style={{borderRadius:'6px', border:'1px solid'}}>Filtrar <FaSyncAlt/></button>
                    <span>&nbsp; &nbsp;&nbsp;  </span>
                    <span hidden>Proveedor &nbsp;</span>
                    <select hidden id='slcProveedor' onChange={()=> filtrarporProveedor()}>
                    {listatres.map(item => ( 
                        <option  value={item.proveedor}>{item.proveedor}</option>
                    ))}
                    </select> <span>Estado &nbsp;</span>
                    <select id='slcEstado' onChange={()=> filtrarporEstado()}>
                    {listaestados.map(item => ( 
                        <option  value={item.estadoorden}>{item.estadoorden}</option>
                    ))}
                    </select>

                </div>
                <div>
                <span>Filtrar proveedor: &nbsp;&nbsp;</span><br />
                <input  id='input-proveedor' type='text' onKeyUp={(e) => filtrarPorProveedor(e)} style={{width:'397px',fontSize:'12px', cursor:'pointer'}} ></input><br></br>
                <select class="slc-b"   id="buscar-pro" onChange={handleSelectProveedor}>                    
                    {listaProveedores.map((proveedor, index) => (
                        <option key={index} value={proveedor.proveedor}>
                            {proveedor.proveedor}
                        </option>
                    ))}
                </select>
          
            <div>
                <button onClick={clearSelectedProveedores}>Borrar Proveedores</button>
            </div>
            <span>Seleccionados:</span>
            <ul>
                {selectedProveedores.map((proveedor, index) => (
                    <li key={index}>{proveedor}</li>
                ))}
            </ul>
                </div>
                <div>
                <span>Filtrar estacion: &nbsp;&nbsp;</span><br />
                <input  id='input-estacion' type='text' onKeyUp={(e) => filtrarPorEstacion(e)} style={{width:'397px',fontSize:'12px', cursor:'pointer'}} ></input><br></br>
                <select class="slc-b"   id="buscar-est" onChange={handleSelectEstacion}>                    
                    {listaEstaciones.map((estacion, index) => (
                        <option key={index} value={estacion.estacion}>
                            {estacion.estacion}
                        </option>
                    ))}
                </select>
            <div>
                <button onClick={clearSelectedEstaciones}>Borrar estaciones</button>
            </div>
            <span>Seleccionados:</span>
            <ul>
                {selectedEstaciones.map((estacion, index) => (
                    <li key={index}>{estacion}</li>
                ))}
            </ul>
                </div>
                
            <div class="div-section1" hidden>
                <table id="productstable"  class="width-100"> 
                    <tr>
                        <th>Folio</th>
                        <th>Fecha</th>
                        <th>Solicitó</th>
                        <th>Estación</th>
                        <th>Proveedor</th>
                        <th>Producto</th>
                        <th>Total</th>
                        <th>Documento</th> 
                        
                    </tr>

                    {lista.map(item => ( 
                    <tr class="font-14">
                        <td className='id-orden' >{item.foliof}</td>
                        <td>{formatDate(item.fechacaptura)}</td>
                        <td>{item.name}</td>
                        <td>{item.estacion}</td>
                        <td>{item.proveedor}</td>
                        <td>{ (!item.producto == "") ? item.producto +' ('+item.idrequisicion+')' : item.identificador +' ('+item.idrequisicion+')'}</td>
 
                        <td>{formatNumber(item.total)}</td>
                        <td>{ (item.documento == null) ?
                        <>
                        </>
                        :
                        <>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><a target="_blank" href={process.env.REACT_APP_API_URL+item.documento} rel="noreferrer" >Ver</a>
                        </> 
                        
                            }
                        
                        </td> 
                      
                    </tr>
                    ))}	
                   
                </table> 
            </div>

            

            
            </div>
                    <div class="margin-auto" hidden><label ><b>&nbsp;&nbsp;&nbsp;&nbsp;TOTAL:</b></label>&nbsp;<label id='TotalL' ></label></div>
                    <div className="row p-3">
                
                
                <h3>Reporte de Ordenes</h3>
            

            <div class="div-section1">
            <DataTableExtensions
          columns={columns}
          data={lista}
          print={true}
          export={true}
          filterPlaceholder={"Filtrar"}
        >
          <DataTable
					columns={columns}
					data={lista}
					fixedHeader={true}
					fixedHeaderScrollHeight={'100%'}
					pagination
					customStyles={tableCustomStyles}
					highlightOnHover={true}
                    noDataComponent={"No hay registros para mostrar"}
				/>
        </DataTableExtensions>
                <table id="productstable"  class="width-100" hidden> 
                    <tr>
                        <th>Orden Compra</th>
                        <th>Proveedor</th>
                        <th>Factura</th> 
                        <th>Importe</th>
                        <th>Concepto</th>
                        <th>Fecha Orden</th>
                        <th>Fecha Pago</th>
                        <th>Estado Orden</th>
                        <th>Observaciones</th> 
                        <th>Actualizar</th>
                         
                        
                    </tr>

                    {lista.map(item => ( 
                    <tr class="font-14">
                        <td className='id-orden' onClick={() => props.verOrden(item.idrequisicion, item.foliof)}>{item.foliof} <FaExternalLinkSquareAlt style={{color:props.defaultColor}}/></td>
                        <td>{item.proveedor}</td>
                        <th><input id={"Factura"+item.ordencompra}  defaultValue={item.factura} style={{width:'55px'}}/></th> 
                        <td>{formatNumber(item.total)}</td>
                        <td onClick={() => props.verReq(item.idrequisicion)}>{ (!item.producto == "") ? item.producto +' ('+item.idrequisicion+')' : item.identificador +' ('+item.idrequisicion+')'} <FaExternalLinkSquareAlt style={{color:props.defaultColor}}/></td>
                        <td>{formatDate(item.fechacaptura)}</td> 
                        <td><input id={"FechaPago"+item.ordencompra}  defaultValue={item.fechapago} type="date" style={{width:'110px'}}/></td>
                        <td>{item.estador}</td>
                         
                        <td><input id={"inputObservacionesSeguimiento"+item.ordencompra}   defaultValue={item.observacionseguimiento}  type="text"  class="width-100" onChange={() => function(){}} ></input></td>
                        <td><button id="btn-actualizar" className="btn btn-outline-success btn-sm" onClick={() => actualizarReporteCompras(item.ordencompra)}>Actualizar</button></td>
                         
                      
                    </tr>
                    ))}	
                     <tr>
                        <td colSpan={2}>Registros: {registros}</td>
                        <td ></td>
                        <td >{formatNumber(totales)}</td>
                    </tr>
                </table>  
            </div>

            <Chart
                
                chartType="BarChart" 
                width="100%"  
                height={"1000px"}  
                options={options}
                data={chartData} 
            />  
            <td >Total : {formatNumber(totales)}</td>
  <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>

            
            </div>

                     
                    <ModalCarga modalIsOpenLoad={modalIsOpenLoad} defaultColor={props.defaultColor} closeModalLoad={closeModalLoad}/>    

                    <Toast/>    
        </div>
    );   
}

export default Ordenes;
