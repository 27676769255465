import add from './resources/add.svg';
import remove from './resources/remove.svg';
import React,{useState, useEffect} from 'react'; 
import  {FaCheckCircle} from 'react-icons/fa'
import axios from '../node_modules/axios'; 
import {Nabvar} from './component/Navbar'; 
import { ModalCarga, Toast} from './component/modalCarga'; 
import './App.css'; 
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";   

function NvaRequisicion(props) {

	  
	const [proveedor, setProveedor] =  useState(props.proveedores); 
	const [suma, setSuma] =  useState([]); 
	
	//const [listaProveedores1, setListaProveedores1] = useState([]); 
	useEffect(()=> {
	  
	  
	}, []) 

	const [modalIsOpenLoad, setIsOpenLoad] = React.useState(false);
	 
	function notify(message){
        toast(message);
    }

	
	function filtrarPorProveedor(id){
		var selProveedores = document.getElementById("pronva"+id);
		var currentInput = document.getElementById("proveedornva"+id);
		var result = props.proveedores1.filter(item => item.proveedor.toLowerCase().includes(currentInput.value.toLowerCase()))
		console.log(result);
		 setProveedor(result);
		 
		if(currentInput.value.toLowerCase() == "" || result.length < 1){
		 selProveedores.style.display = "none";
		}else{
		 selProveedores.style.display = "block";
		 selProveedores.selectedIndex = -1;
		 selProveedores.size = 5;
		 var my_index = 100;
		 selProveedores.style.zIndex = my_index++;
		}
	 }
	 function proveedorSelected(id){
		document.getElementById("proveedornva"+id).value = document.getElementById("pronva"+id).value; 
		document.getElementById("pronva"+id).style.display = "none"; 
	}
 

    function openModalLoad() { 
    setIsOpenLoad(true); 
     }  
   
     function closeModalLoad() { 
    setIsOpenLoad(false); 
     }


	 
	 

    async function addRequisicion(e) {
        e.preventDefault(); 
        var userid = props.userid;
        var observaciones = document.getElementById("observacionesnva").value;
		var dptoid = document.getElementById("dptoidnva").value; 
		var dptoidelabora = document.getElementById("dptoidelaboranva").value; 
		var identificador = document.getElementById("identificadornva").value; 
		identificador =identificador.replaceAll('"', '´´').replaceAll("'", "´");
		observaciones =observaciones.replaceAll('"', '´´').replaceAll("'", "´");
		if(identificador == ""){
			alert("Capture el identificador de la requisición para continuar");
		}else{
			openModalLoad(); 
			let fd = new FormData()
			fd.append("id", "InsertRequisicion")
			//fd.append("fecha", fecha)
			fd.append("userid", userid)
			fd.append("observaciones", observaciones)
			fd.append("dptoid", dptoid)
			fd.append("dptoidelabora", dptoidelabora)
			fd.append("empresaid", props.dptoid)
			fd.append("identificador", identificador)
			fd.append("nombresolicita", props.name)
	
			for(let i=0;i<document.getElementsByName("productonva").length;i++){
				if(document.getElementsByName("descripcionnva")[i].value != ""){
				fd.append("producto[]", document.getElementsByName("productonva")[i].value.replaceAll('"', '´´').replaceAll("'", "´"))  
				fd.append("descripcion[]", document.getElementsByName("descripcionnva")[i].value.replaceAll('"', '´´').replaceAll("'", "´"))  
				fd.append("unidad[]", document.getElementsByName("unidadnva")[i].value)  
				fd.append("cantidad[]", document.getElementsByName("cantidadnva")[i].value)  
				fd.append("precio[]", document.getElementsByName("preciouninva")[i].value)  
				}
			}
	
			for(let i=0;i<document.getElementsByName("proveedornva").length;i++){
				fd.append("proveedores[]", document.getElementsByName("proveedornva")[i].value.replaceAll('"', '´´').replaceAll("'", "´"))  
				fd.append("precios[]", document.getElementsByName("precionva")[i].value)      
			} 
	
			const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
			closeModalLoad();
			if(res.data == "1"){
				notify("✔ Datos guardados correctamente");
				
				for(let i=0;i<document.getElementsByName("productonva").length;i++){
					document.getElementsByName("productonva")[i].value = "";
					document.getElementsByName("descripcionnva")[i].value = "";
					document.getElementsByName("unidadnva")[i].value = ""; 
					document.getElementsByName("cantidadnva")[i].value = "";
					document.getElementsByName("preciouninva")[i].value = "";
				}
	
				for(let i=0;i<document.getElementsByName("proveedornva").length;i++){
					document.getElementsByName("proveedornva")[i].value = ""; 
					document.getElementsByName("precionva")[i].value = "";      
				} 
	
				document.getElementById("observacionesnva").value = ""; 

				//refrescar listado de actividades

				props.nuevaRequisicionGuardada();
			}
			 
		}
	
	}

	//const [value, setValue] = useState([]); 

 
	function Agregar() {  
		var table = document.getElementById('tableproductosnva');
	
		var newrow = table.lastChild.cloneNode(true);
		var newRowNumber = parseFloat(newrow.firstChild.innerHTML) + 1; 
	
		// Update IDs for input fields and checkboxes
		var inputs = newrow.querySelectorAll("input[name='productonva'], input[name='descripcionnva'], input[name='cantidadnva'], input[name='preciouninva']");
		inputs.forEach((input, index) => {
			input.setAttribute("id", "checknva" + (index + newRowNumber));
			input.value = "";
		});
	
		var checkbox = newrow.querySelector("input[name='checkboxnva']");
		checkbox.addEventListener("change", (e) => Change(e));
		checkbox.setAttribute("id", "checknva" + newRowNumber);
		checkbox.checked = false;
	
		table.appendChild(newrow);  
		
		// Update IDs for existing precio input fields
		var precioInputs = table.querySelectorAll("input[name='preciouninva']");
		precioInputs.forEach((input, index) => {
			input.setAttribute("id", "inputnva" + index);
			input.addEventListener("change", (e) => sumarPrecio(e));
			input.addEventListener("keyup", (e) => sumarPrecio(e));
		});
	}
	

	function quitar(){ 
		var table = document.getElementById('tableproductosnva');
		//let tbl = document.getElementById("tableproductos");
            let rows = table.getElementsByTagName('tr');
            rows = Array.from(rows);
            rows.shift();
			var cont = 0;
            for(let element of rows){
                //element.remove();
				cont ++;
            } 
			if(cont > 1){
				table.lastChild.remove();
			}
		   
	}

 
  	// Dynamically create select list
//	let options = [];

	function sumarPrecio(e){
		let suma = 0;
		for(let i=0;i<document.getElementsByName("preciouninva").length;i++){
			let cantidad = Number(document.getElementsByName("cantidadnva")[i].value);
			if(cantidad == 0){
				cantidad = 1;
			} 
			suma = suma +  (cantidad *  Number(document.getElementsByName("preciouninva")[i].value));
			console.log(suma);
			setSuma(suma);
		}
	}
	function Change(e) {
		const checkbox = e.target;
		const row = checkbox.closest("tr"); // Find the closest parent <tr> element
	
		// Find the precio input field within the same row
		const precioInput = row.querySelector("[name='preciouninva']");
	
		if (checkbox.checked) {
			// Checkbox is checked
			if (precioInput) {
				// Store the original value if not already stored
				if (!precioInput.dataset.originalValue) {
					precioInput.dataset.originalValue = precioInput.value;
				}
				// Reduce the price by removing tax
				precioInput.value = (parseFloat(precioInput.value) / 1.16).toFixed(2);
			}
		} else {
			// Checkbox is unchecked
			if (precioInput) {
				// Restore the original value
				precioInput.value = precioInput.dataset.originalValue || "";
				// Remove the stored original value
				delete precioInput.dataset.originalValue;
			}
		}
	
		// Recalculate the total price
		sumarPrecio();
	}
	

	const today = new Date().toISOString().slice(0, 10);

	return (
		<div className="container "  id="nvarequisicion" hidden >
			<Nabvar  defaultColor={props.defaultColor} idd="navnuevarequisicion" titulo="Nueva Requisición" dptoid={props.dptoid} departamento={props.rzonsocial} />    
			 
			<div className="row p-3">
				<div class="margin-auto"> 
					<br></br> 
					<div style={{backgroundColor:'white', border:'none', boxShadow: 'rgb(0 0 0 / 45%) 0px 5px 15px', borderRadius:'5px', width:'100%', margin:'auto', padding:'12px'}}> {/* width:'600px' */}
					<br></br> 
					<div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <div class="div-section2">
                                <img id="logoOrdennva" src={"data:image/png;base64,"+props.datose.logoblob}  alt="LOGO" style={{width: '160px', height: 'auto', paddingLeft:'25px'}}></img>
                                <span><b class="font-25">NUEVA REQUISICIÓN&nbsp;&nbsp;&nbsp;&nbsp;</b></span>
                            </div>
            
                            <div class="div-section2">
                                <span><b class="font-30" id="rzonsocialnva">{props.datose.name}</b></span>
                                <span><b id="direccionnva">{props.datose.direccion}</b></span> 
                                <span><b>Tel. </b><b id="telempresanva">{props.datose.telefono}</b></span>
                            </div>

                            <div class="div-section2">
                                <table class="border-line" >
                                    <tr align="center" hidden="hidden">
                                        <th class="cell-1">Folio</th> 
                                    </tr>
                                    <tr>
                                        <td  hidden="hidden">
                                            <input id="folio" readOnly="readonly" class="input-1"></input>
                                            <input id="idrequisicion" readOnly="readonly" class="input-1"hidden></input>
                                        </td> 
                                    </tr>
                                </table> 
                                <table class="border-line">
                                    <tr align="center">
                                        <th class="cell-1">Fecha</th> 
                                    </tr>
                                    <tr>
                                        <td>
                                            <input id="fechacaptura" readOnly='readonly' class="input-1" value={(today).split("-").reverse().join("/")}></input> 
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
						<table class="width-100"> 
							<tr>
								<td>
									<label>Solicita:</label> 
									<br></br>  
									<select id="dptoidnva"  class="select-req">
									<option selected value={props.departamentoid}> {props.departamento}</option>
										{ (props.dptos1).map(item => ( 
										<option value={item.dptoid}> {item.name}</option>))
										}  
									</select> 
								</td>  
								 <td>
									<label >Elabora:</label>
					
									<br></br> 
									  
									<select id="dptoidelaboranva"  class="select-req">
									<option selected value={props.departamentoid}> {props.departamento}</option>
										{ (props.dptos1).map(item => ( 
										<option value={item.dptoid}> {item.name}</option>))
										}  
									</select> 
								</td> 
							</tr>
							<tr> 
							</tr>
							
							<tr>
								<td>
									<label >Solicitado Por:</label>
									<br/>
									<input class="inpt-nva-req" value={props.name}/>
								</td>  
								<td>
									<label >Identificador:</label>
									<br/>
									<input  class="inpt-nva-req" id="identificadornva"/>
								</td>  
								<td>
									<input  value={props.dptoid} hidden="hidden"/>
								</td>	
								
 	
							</tr>
							<tr> 
							</tr>  
						</table>	
						<br/>
		
						<table id="tableproductosnva">
							<tr>
								<th></th>  
								<td class="cell-productos" hidden="hidden"><label>Producto</label></td>
								<td class="cell-productos"><label>Producto</label></td>
								<td class="cell-productos"><label>Unidad</label></td>
								<td class="cell-productos"><label>Cantidad</label></td>
								<td class="cell-productos"><label>Precio</label></td>
								<td class="cell-productos"><label>IVA (-)</label></td>
							</tr>

							<tr class="width-100">
								<td class="width-2">1</td>
								<td hidden="hidden"> <input   style={{width:'162px', height:'auto'}} name="productonva" /></td> 
								<td style={{width:'63%'}}> <input name="descripcionnva" class="width-100" style={{height:'auto'}}/></td>
								<td class="width-10"> <select name="unidadnva"  class="select-req-1">
								{props.unidades.map(item => ( 
                                            <option  value={item.unidad}>{item.unidad}</option>))
                                            } 
										 	 </select>
								</td>  
								<td class="width-10"> <input name="cantidadnva" type="number" min="1" step="any" class="inpt-nva-req" /></td>
								<td class="width-10"> <input name="preciouninva" type="number" id="inputnva0" min="1" onChange={(e) => sumarPrecio(e)} step="any" class="inpt-nva-req" /></td>
								<td class="width-2" align="center"><input type="checkbox" id="checknva0" onChange={(e) => Change(e)} name="checkboxnva"/></td>
							</tr>
						</table>	
						<div class="div-section-row jcend mtop5"  >
						<img onClick={() => Agregar()} alt="Agregar" src={add} className='btn btn-outline btn-sm padding2 bggreen'  ></img>
						<img onClick={() => quitar()} alt="Remover" src={remove} className='btn btn-outline btn-sm padding2' style={{ width: '30px', height: '30px',backgroundColor:'#ff0000', marginLeft: '1.6%'}}></img>
						</div>
						<br/>
						
						<table>
							<tr>
								<th></th>
								<td>Proveedor</td>
								<td>Precio cotizado</td> 
							</tr>
		
							<tr class="width-100">
								<td class="width-2">1</td>
								<td class="width-88"> 
								<input id="proveedornva1" type="text" name="proveedornva" onKeyUp={() => filtrarPorProveedor("1")} style={{width:'97.5%', height:'auto', cursor:'pointer'}}/>
                                    
                                        <select  id="pronva1" class="slc-b" onChange={() => proveedorSelected("1")}>
                                      
                                            {proveedor.map(item => ( 
                                            <option  value={item.proveedor}> {item.proveedor}</option>))
                                            }   
                                        </select>
                                        <br></br>
                                        
                                       
								</td> 
								<td class="width-20"> 
									<input name="precionva" type="number" min="1" step="any" defaultValue={suma} class="inpt-nva-req" />
								</td> 
							</tr>

							<tr class="width-100">
								<td class="width-2">2</td>
								<td>  
								<input id="proveedornva2" type="text" name="proveedornva" onKeyUp={() => filtrarPorProveedor("2")} style={{width:'97.5%', height:'auto', cursor:'pointer'}}/>
                                    
                                        <select  id="pronva2" class="slc-b" onChange={() => proveedorSelected("2")}>
                                           
                                            {proveedor.map(item => ( 
                                            <option  value={item.proveedor}> {item.proveedor}</option>))
                                            }   
                                        </select>
                                        <br></br>
                                        
                                       
								</td> 
								<td class="width-20"> 
									<input name="precionva"  type="number" min="1" step="any"class="inpt-nva-req" />
								</td> 
							</tr>

							<tr class="width-100">
								<td class="width-2">3</td>
								<td>  
								<input id="proveedornva3" name="proveedornva" type="text" onKeyUp={() => filtrarPorProveedor("3")} style={{width:'97.5%', height:'auto', cursor:'pointer'}}  />
                                    
                                        <select  id="pronva3" class="slc-b" onChange={() => proveedorSelected("3")}>
                                           
                                            {proveedor.map(item => ( 
                                            <option  value={item.proveedor}> {item.proveedor}</option>))
                                            }   
                                        </select>
                                        <br></br>
                                        
                                       
								</td>
								<td class="width-20"> 
									<input name="precionva"  type="number" min="1" step="any" class="inpt-nva-req" />
								</td> 
							</tr> 
						</table>	
						<br/>
						
						<table class="width-100" style={{ marginRight:'0'}}>
							<tr>
								<td>Observaciones</td>
							</tr>

							<tr>
								<td> 
									<textarea rows="2" id="observacionesnva" class="width-100"  style={{height:'140px',   resize: 'none'}}></textarea>
								</td> 
							</tr> 

							<tr>
								<td>
									<button  className="btn btn-outline-success btn-sm" onClick={(e) => addRequisicion(e)} >Guardar <FaCheckCircle /></button> 
								</td>
							</tr>
						</table>	
					</div>
				</div>
			</div>
			 
			<ModalCarga modalIsOpenLoad={modalIsOpenLoad} defaultColor={props.defaultColor} closeModalLoad={closeModalLoad}/>    
			<Toast/>    
		</div>
	);   
}

export default NvaRequisicion;