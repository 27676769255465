import React, { useState, useEffect } from 'react'
import App from '../App';
import Proveedores from '../Proveedores';
import Requisiciones from '../Requisiciones';
import NvaRequisicion from '../Nvarequisicion';
import Ordenes from '../Ordenes';
import Rordenes from '../Rordenes';
import ChangePassForm from '../ChangePassForm'; 
import Config from '../Config';
import OrdPago from '../OrdPago'; 
import Egresos from '../Egresos'; 
import SolicitudesOrdenPago from '../SolicitudesOrdenPago';  
import OpcionesMenu from './OpcionesMenu';
import Push from 'push.js';
import Pusher from 'pusher-js';
import axios from 'axios'; 
import {toast } from 'react-toastify';
import {Toast} from './modalCarga'; 
import 'react-toastify/dist/ReactToastify.css';

export default function SideMenuHam(props) {

    const [uno, setUno] = useState(false);
    const [dos, setDos] = useState(false);
    const [tres, setTres] = useState(false);
    const [cuatro, setCuatro] = useState(false);
    const [cinco, setCinco] = useState(false);
    const [seis, setSeis] = useState(false);
    const [siete, setSiete] = useState(false);
    const [ocho, setOcho] = useState(false);

    const [selected, setSelect] = useState(props.selected); 
    const [isMenuOpen1, SetIsMenuOpen1] = useState(true);
    const [razon, setRazon] = useState(props.rzonsocial);
    const [dpto, setDpto] = useState(props.dptoid);


    const [cargarRequisicion, setCargarRequisicion] = useState([]);
    const [cargarRequisicion1, setCargarRequisicion1] = useState([]);
    const [cargarOrden, setCargarOrden] = useState([]);
    const [cargarOrdenPago, setCargarOrdenPago] = useState([]); 
    const [datosE, setDatosE] = React.useState([]);
    const [bancos, setBancos] =  useState([]);  
    const [tarjetas, setTarjetas] = useState([]); 
    const [categorias, setCategorias] = useState([]);
    
    
    const [empresas, setEmpresas] = useState([]); 
    const [unidades, setUnidades] = useState([]); 

/*proveedores y lista de filtrado*/
    const [proveedor, setProveedor] =  useState([]);  
	const [listaProveedores1, setListaProveedores1] = useState([]); 


    const [usuarios, setUsuarios] = useState([]);


    const [dptos1, setDeptos1] = useState([]); 
    
 
    const Element = () => {

        if (selected == '1') {
            return <App empresas={empresas} usuarios={usuarios} getUsuarios={getUsuarios} superus={props.superus} defaultColor={props.defaultColor} tipo={props.tipo} departamento={props.departamento} dptoid={dpto} rzonsocial={razon} userid={props.userid}/>;
        } else if (selected == '2' && uno == true && dos == true && tres == true && cuatro == true && cinco == true && seis == true && siete == true && ocho == true ) {
            return <Requisiciones setProveedor={setProveedor} proveedores={proveedor} proveedores1={listaProveedores1} usuarios={usuarios} datose={datosE} unidades={unidades} dptos1={dptos1} superus={props.superus} verOrden={verOrden} departamentoid={props.departamentoid}  departamento={props.departamento} cargarDefault={cargarRequisicion} defaultColor={props.defaultColor} userdptoid={props.userdptoid} tipo={props.tipo}   dptoid={dpto} userid={props.userid} usuario={props.usuario} name={props.name} rzonsocial={razon} />;
        } else if (selected == '3') {
            return <NvaRequisicion defaultColor={props.defaultColor} departamentoid={props.departamentoid} tipo={props.tipo} departamento={props.departamento} dptoid={dpto} userid={props.userid} usuario={props.usuario} name={props.name} rzonsocial={razon} />;
        } else if (selected == '4') {
            return <Ordenes restableceoc={props.restableceoc} dptos2={dptos1} proveedores={proveedor} proveedores1={listaProveedores1} usuarios={usuarios} datose={datosE} userdptoid={props.userdptoid} superus={props.superus} bancos={bancos} tarjetas={tarjetas} verReq={verReq} verOrdenPago={verOrdenPago} cargarDefaultR={cargarRequisicion1} cargarDefaultO={cargarOrden}defaultColor={props.defaultColor} tipo={props.tipo} departamento={props.departamento} dptoid={dpto} userid={props.userid} usuario={props.usuario} name={props.name} rzonsocial={razon} />;
        }else if (selected == '8') {
            return <Rordenes verReq={verReq} verOrden={verOrden} defaultColor={props.defaultColor} tipo={props.tipo} departamento={props.departamento} dptoid={dpto} userid={props.userid} usuario={props.usuario} name={props.name} rzonsocial={razon} />;
        } else if (selected == '5') {
            return <ChangePassForm defaultColor={props.defaultColor} userid={props.userid}/>;
        } else if (selected == '6') {
            return <OrdPago  categorias={categorias} usuarios={usuarios} getUsuarios={getUsuarios} bancos={bancos} tarjetas={tarjetas} verOrden={verOrden2} defaultColor={props.defaultColor} cargarDefaultOP={cargarOrdenPago} tipo={props.tipo} departamento={props.departamento} dptoid={dpto} userid={props.userid} usuario={props.usuario} name={props.name} rzonsocial={razon} />;
        } else if (selected == '9') {
            return <Config getUnidades={getUnidades} getCategorias={getCategorias} unidades={unidades} getEmpresas={getEmpresas} empresas={empresas} getBancos={getBancos} bancos={bancos} usuarios={usuarios} categorias={categorias} defaultColor={props.defaultColor} defaultLogo={props.defaultLogo} userid={props.userid} unmountMe={cambiarRazon} />;
        }else if (selected == '10') {
            return <Proveedores defaultColor={props.defaultColor} defaultLogo={props.defaultLogo} userid={props.userid} unmountMe={cambiarRazon} usuario={props.usuario} name={props.name} rzonsocial={razon}/>;
        }else if (selected == '11') {
            return <SolicitudesOrdenPago verOrdenPago={verOrdenPago} datose={datosE} tarjetas={tarjetas} bancos={bancos} usuarios={usuarios}  setProveedor={setProveedor} proveedores={proveedor} proveedores1={listaProveedores1}  unidades={unidades} dptos1={dptos1} superus={props.superus} verOrden={verOrden} departamentoid={props.departamentoid}  departamento={props.departamento} cargarDefault={cargarRequisicion} defaultColor={props.defaultColor} userdptoid={props.userdptoid} tipo={props.tipo}   dptoid={dpto} userid={props.userid} usuario={props.usuario} name={props.name} rzonsocial={razon} />;
        }else if (selected == '12') {
            return <Egresos verOrdenPago={verOrdenPago} datose={datosE} tarjetas={tarjetas} bancos={bancos} usuarios={usuarios}  setProveedor={setProveedor} proveedores={proveedor} proveedores1={listaProveedores1}  unidades={unidades} dptos1={dptos1} superus={props.superus} verOrden={verOrden} departamentoid={props.departamentoid}  departamento={props.departamento} cargarDefault={cargarRequisicion} defaultColor={props.defaultColor} userdptoid={props.userdptoid} tipo={props.tipo}   dptoid={dpto} userid={props.userid} usuario={props.usuario} name={props.name} rzonsocial={razon} />;
        } else {
            return (<div id="errorContainer" ><h1></h1></div>);
        }

        
    }
    function notify(message){
		toast(message);
	}


    
    function cambiarSelected(selected){ 
        setCargarRequisicion(""); 
        setCargarRequisicion1(""); 
        setCargarOrden(""); 
        setCargarOrdenPago(""); 
        close(selected);   
    } 

    useEffect(() => { 
        notificaciones();
        getBancos();
        getAllTarjetas();
        getDepartamentos();
        getDatosEmpresa(props.rzonsocial);
        getUsuarios();
        getProveedores();
        getCategorias();
        getEmpresas();
        getUnidades();
        if(props.defIdOrden != null && props.defIdOrden != ""){
            
            verOrdenNotificacionMobile(props.defIdRequisicion, props.defIdOrden);
           

        } else{ 
        }
    }, [])


     async  function getProveedores(){
        var id = "getProveedores";
        const rese = await axios.get(process.env.REACT_APP_API_URL+'?id='+id);  
        setProveedor(rese.data);  
		setListaProveedores1(rese.data);  
		setSeis(true);
    }

	async function getUsuarios(){
        setUsuarios([]);
            var id = "getUsuarios";
       // openModalLoad();
            const rese = await axios.get(process.env.REACT_APP_API_URL+'?id='+id); 
        //closeModalLoad();
              console.log(rese.data);
            setUsuarios(rese.data);    
            setCinco(true);
        } 
    
    async function getDatosEmpresa(rzonsocial){
        var id = "getDatosEmpresa"; 

        const rese = await axios.get(process.env.REACT_APP_API_URL+'?id='+id+'&rzonsocial='+rzonsocial);  
        setDatosE(rese.data[0])
        setCuatro(true);
    }

    async function getEmpresas(){
		var id = "getEmpresas"; 
		const rese = await axios.get(process.env.REACT_APP_API_URL+'?id='+id);   
		setEmpresas(rese.data);   
        setOcho(true);
	}
 
 
    
	async function getDepartamentos(){
		var id = "getDepartamentoss";
		const rese = await axios.get(process.env.REACT_APP_API_URL+'?id='+id);  
        /*const dptosOrdenedos = rese.data
        .sort((a, b) => b.ID - a.ID)*/
		setDeptos1(rese.data);  
        setTres(true);
	}


    async function getBancos(){
        var id = "getBancos";
        const rese = await axios.get(process.env.REACT_APP_API_URL+'?id='+id);  
        console.log(rese.data);
       setBancos(rese.data);
       setUno(true);
    }
    async function getAllTarjetas(){
		var id = "getAllTarjetas"; 
		const rese = await axios.get(process.env.REACT_APP_API_URL+'?id='+id);  
		//console.log(rese.data);
		setTarjetas(rese.data);  
        setDos(true);
	}

    async function getCategorias(){
        setCategorias([]);
        var id = "getCategorias";
        const rese = await axios.get(process.env.REACT_APP_API_URL+'?id='+id);  
        //console.log(rese.data);
        setCategorias(rese.data);
        setSiete(true);
    }


     async function getUnidades(){
        setUnidades([]);
        var id = "getUnidades";
        const rese = await axios.get(process.env.REACT_APP_API_URL+'?id='+id);  
     //   console.log(rese.data);
        setUnidades(rese.data);
       // setSiete(true);
    }




    function Not(data){  
        if(data.userid == props.userid && data.tipo == "com"){ 
                    Push.create("Notificación: ", {  
                    body:  data.mensaje,  
                    icon: process.env.REACT_APP_API_URL_DEFAULT+props.defaultLogo,  
                    timeout: null,  
                    onClick: function () {  
                    window.focus();  
                    this.close();  
                }  
            });       
        } 
 } 

 function notificaciones(){ 
         
    var pusher = new Pusher('5238df05729a28dcfb1a', {  
    cluster: 'us3'  
    });  
    var channel = pusher.subscribe('my-channel');  
     channel.bind('my-event', function(data) {  
     
      Not(data);   
    }); 
    
      }
    
    function cambiarRazon(value, dpto){
       /*  */  
        setSelect(selected);
        setRazon(value); 
        setDpto(dpto);  
        console.log("a");
        notify("Razón social actualizada a: " + value);
        setCargarRequisicion(""); 
        setCargarRequisicion1(""); 
        setCargarOrden(""); 
        setCargarOrdenPago("");
        getDatosEmpresa(value);
     
    }

    function verReq(idrequisicion){
        
        //set selected requisiciones send idrequisicion
        setCargarRequisicion(idrequisicion); 
        if(window.confirm('¿Ver requisición: ' + idrequisicion +'?')){
            setSelect('2');
        }
        
    }
    function verOrden(idrequisicion, idorden){
        
        //set selected requisiciones send idrequisicion
        setCargarRequisicion1(idrequisicion); 
        setCargarOrden(idorden); 
        if(window.confirm('¿Ver orden de compra: ' + idorden +'?')){
            setSelect('4');
        }
        
    }
    function verOrdenNotificacionMobile(idrequisicion, idorden){
        
        //set selected requisiciones send idrequisicion
        setCargarRequisicion1(idrequisicion); 
        setCargarOrden(idorden); 
       // if(window.confirm('¿Ver orden de compra: ' + idorden +'?')){
            setSelect('4');
        //}
        
    }
    function verOrden2(idrequisicion, idorden, folioordenpago){
         
        setCargarRequisicion1(idrequisicion); 
        setCargarOrden(idorden); 
        if(window.confirm('¿Ver orden de compra: ' + idorden +'?')){
            setSelect('4');
        } 
        
    }
    function verOrdenPago(ID, ordencompraf){
         
        setCargarOrdenPago(ID); 
        if(window.confirm('¿Ver orden de pago: ' + ID +'?')){
            setSelect('6');
        }
        
    }

    function close(selected){ 
        setSelect(selected); 
        if(isMenuOpen1 == true){ 
            SetIsMenuOpen1(false); 
        }  
    }
   
 
    return (

      <div id="mainContainer"  > 
          <OpcionesMenu modulos={props.modulos} unmountMe={cambiarRazon} razon={props.rzonsocial}  defaultColor={props.defaultColor} defaultLogo={props.defaultLogo}  departamento={props.departamento} tipo={props.tipo} unmount={cambiarSelected}  name={props.name} isMenuOpen1={isMenuOpen1} userid={props.userid}></OpcionesMenu>     
          <Element selected={selected} verReq={verReq} />
          <Toast/>    
        </div>
        
    )
   
}
