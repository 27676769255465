
import React,{useState, useEffect} from 'react'; 
import  {FaCheckCircle, FaTrash, FaTimesCircle, FaSyncAlt, FaExternalLinkSquareAlt, FaShare, FaBell} from 'react-icons/fa'
import ProgressBar from 'react-bootstrap/ProgressBar';
import DataTable from 'react-data-table-component';
import add from './resources/add.svg'; 
import axios from '../node_modules/axios'; 
import {Nabvar} from './component/Navbar'; 
import {ModalCarga, Toast} from './component/modalCarga'; 
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';  
import NvaRequisicion from './Nvarequisicion';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";   
import customStyles from './component/modalCustomStyles';
import tableCustomStyles from './tableCustomStyles';
import DocViewer,  {DocViewerRenderers} from "react-doc-viewer";
import FileViewer from 'react-file-viewer';
import formatDate from './formatDate'; 
import DataTableExtensions from "react-data-table-component-extensions";

/* Audio, BallTriangle, Bars, Circles, Grid, Hearts, Oval, Puff, Rings, TailSpin */
function Requisiciones(props) {
	const [couno, setCouno] =  useState([]);  
	const [codos, setCodos] =  useState([]);  
	const [cotres, setCotres] =  useState([]);  
	const [listaProveedores, setListaProveedores] = useState([]); 
	const [lista, setLista] =  useState([]);   
	const [idcotizacion, setIdCotizacion] = useState([]); 
	const [proveedores, setLProveedores] = useState([]); 
	 
	const [btnText, setBtnText] = useState("Nueva requisición"); 
	const [modalIsOpenLoad, setIsOpenLoad] = React.useState(false);
	const [nuevaR, setNuevaR] = React.useState(false);
	const [modalIsOpenFile, setIsOpenFile] = React.useState(false);
	const [currentFile, setCurrentFile] = React.useState(false);
	 
	//estados
	const [generada, setGenerada] = React.useState([]);
	const [conorden, setConOrden] = React.useState([]);
	const [surtida, setSurtida] = React.useState([]);
	const [recepcionconfirmada, setRecepcionConfirmada] = React.useState([]);
	//cancelada
	const [cancelada, setCancelada] = React.useState([]);
	let id = 0;  

	const[btn_Generar, setbtn_Generar] = useState();
 
	const [proveedor, setProveedor] =  useState(props.proveedores); 
	//const [listaProveedores, setListaProveedores] = useState([]); 
//	const [listaProveedores1, setListaProveedores1] = useState([]); 


	useEffect(()=> { 
		if(props.cargarDefault != ""){
			verRequisicion(props.cargarDefault);
		} 
		getRequisiciones();
		 
		 
   
		console.log(props.datose);
		 
	}, [])
 
 



	function nuevaRequisicionGuardada(){
	  getRequisiciones();
	 }

 

	 
 
	function filtrarPorProveedor(id){
		var selProveedores = document.getElementById("pro1"+id);
		var currentInput = document.getElementById("proveedor1"+id);
		var result = props.proveedores1.filter(item => item.proveedor.toLowerCase().includes(currentInput.value.toLowerCase()))
		console.log(result);
		 setProveedor(result);
		if(currentInput.value.toLowerCase() == "" || result.length < 1){
		 selProveedores.style.display = "none";
		}else{
		 selProveedores.style.display = "block";
		 selProveedores.selectedIndex = -1;
		 selProveedores.size = 5;
		 var my_index = 100;
		 selProveedores.style.zIndex = my_index++;
		}
	 }
 
	 function proveedorSelected(id){
		 document.getElementById("proveedor1"+id).value = document.getElementById("pro1"+id).value; 
		 document.getElementById("pro1"+id).style.display = "none"; 
	 }


	 function filtrarPorProveedor1(id){
		var selProveedores = document.getElementById("buscar-pro-req");
		var currentInput = document.getElementById("input-proveedor-req");
		var result = props.proveedores1.filter(item => item.proveedor.toLowerCase().includes(currentInput.value.toLowerCase()))
		//console.log(result);
		 setListaProveedores(result);
		if(currentInput.value.toLowerCase() == "" || result.length < 1){
		 selProveedores.style.display = "none";
		}else{
		 selProveedores.style.display = "block";
		 selProveedores.selectedIndex = -1;
		 selProveedores.size = 5;
		 var my_index = 100;
		 selProveedores.style.zIndex = my_index++;
		}
	 }
 
	 function proveedorSelected1(id){
		 document.getElementById("input-proveedor-req").value = document.getElementById("buscar-pro-req").value; 
		 document.getElementById("buscar-pro-req").style.display = "none"; 
	 }

	function openModalLoad() { 
		setIsOpenLoad(true); 
		 }  
	   
		 function closeModalLoad() { 
		setIsOpenLoad(false); 
		 }

		  
		  function notify(message){
			toast(message);
		}
	
	async function addPersona(tipo){
		var tv = "0";
	if(tipo == "1"){
		 tv = document.getElementById("slc-solicita").value
	}else if(tipo == "2"){
		 tv = document.getElementById("slc-revisa").value
	}else if(tipo == "3"){
		 tv = document.getElementById("slc-realiza").value
	}else if(tipo == "4"){
		 tv = document.getElementById("slc-autoriza").value

	}else if(tipo == "11"){
		tv = document.getElementById("slc-solicita-au").value
	}else if(tipo == "12"){
		tv = document.getElementById("slc-revisa-au").value
	}else if(tipo == "13"){
		tv = document.getElementById("slc-realiza-au").value
	}else if(tipo == "14"){
		tv = document.getElementById("slc-autoriza-au").value
	}
		if(window.confirm('¿Está seguro? agregar a: ' + tv )){
			var folio = document.getElementById("folio").value;
			let fd = new FormData() 
			fd.append("id","7") 
			fd.append("folio",folio) 
			fd.append("tipo", tipo) 
			fd.append("name", tv)
			const res = await axios.post(process.env.REACT_APP_API_URL, fd);
			notify(res.data.trim()); 
			if(tipo == "1"){
				document.getElementById("slc-solicita").disabled = true;
				document.getElementById("solicitar").style.display = "none";
				document.getElementById("solicitarC").style.display = "block";
			} else if(tipo == "11") {
				document.getElementById("slc-solicita-au").disabled = true;
				document.getElementById("solicitar-au").style.display = "none";
				document.getElementById("solicitarC-au").style.display = "block";
			} else if(tipo == "2"){
				document.getElementById("revisarC").style.display = "block";
				document.getElementById("slc-revisa").disabled = true;
				document.getElementById("revisar").style.display = "none";
			}else if(tipo == "12"){
				document.getElementById("revisarC-au").style.display = "block";
				document.getElementById("slc-revisa-au").disabled = true;
				document.getElementById("revisar-au").style.display = "none";
			}else if(tipo == "3"){
				document.getElementById("slc-realiza").disabled = true;
				document.getElementById("realizar").style.display = "none";
				document.getElementById("realizarC").style.display = "block";
			}else if(tipo == "13"){
				document.getElementById("slc-realiza-au").disabled = true;
				document.getElementById("realizar-au").style.display = "none";
				document.getElementById("realizarC-au").style.display = "block";
			}else if(tipo == "4") {
				document.getElementById("slc-autoriza").disabled = true;
				document.getElementById("autorizar").style.display = "none";
				document.getElementById("autorizarC").style.display = "block";
			}else if(tipo == "14") {
				document.getElementById("slc-autoriza-au").disabled = true;
				document.getElementById("autorizar-au").style.display = "none";
				document.getElementById("autorizarC-au").style.display = "block";
			}
			
		}

	} 

	async function  actualizarDepartamento(dptoid, tipo){
		var idrequisicion = document.getElementById("folio").value;
		if(window.confirm('Actualizar departameto para requisición con folio: '+ idrequisicion)){
			let fd = new FormData() 
			fd.append("id", "actualizarDepartamento")
			fd.append("idrequisicion", idrequisicion)
			fd.append("dptoid", dptoid)
			fd.append("tipo", tipo)
			const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
			
			notify(res.data.trim());
			verRequisicion(idrequisicion);
			getRequisiciones();
		} 
		
	}
	async function removerCotizacionFCotizaciones(idcotizacion){ 
		var idrequisicion = document.getElementById("folio").value;
        if(window.confirm('¿Quitar cotización?')){ 
            let fd = new FormData()  
            fd.append("id", "removerCotizacionFCotizaciones")
            fd.append("idcotizacion", idcotizacion) 
            const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
            notify(res.data.trim());
            verRequisicion(idrequisicion);
        }
    }

	async function confirmarRecepcion(idrequisicion){  
        if(window.confirm('¿Confirmar recepción?')){ 
            let fd = new FormData()  
            fd.append("id", "confirmarRecepcion")
            fd.append("idrequisicion", idrequisicion) 
            const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
            notify(res.data.trim());
           getRequisiciones();
        }
    }
 

	async function CancelarAutoriza(e){ 
	 
		let nombre="";
		var folio = document.getElementById("folio").value;
		if(e==1){
			nombre =document.getElementById("slc-solicita").value;
		}
		if(e==11){
			nombre =document.getElementById("slc-solicita-au").value;
		}
		if(e==2){
			nombre =document.getElementById("slc-revisa").value;
		}
		if(e==12){
			nombre =document.getElementById("slc-revisa-au").value;
		}
		if(e==3){
			nombre =document.getElementById("slc-realiza").value;
		}
		if(e==13){
			nombre =document.getElementById("slc-realiza-au").value;
		}
		if(e==4){
			nombre =document.getElementById("slc-autoriza").value;
		}
		if(e==14){
			nombre =document.getElementById("slc-autoriza-au").value;
		}
		let fd = new FormData() 
		fd.append("id","BorrarAutorizacion") 
		fd.append("folio",folio) 
		fd.append("tipo", e) 
		fd.append("name", nombre)
		//console.log(folio + document.getElementById("slc-autoriza").value);
		const res = await axios.post(process.env.REACT_APP_API_URL, fd);
		notify(res.data.trim());
		//verRequisicion(folio);
		if(e==1){
		document.getElementById("slc-solicita").disabled = false;
		document.getElementById("solicitar").style.display = "block";
		document.getElementById("solicitarC").style.display = "none";
		document.getElementById("slc-solicita").selectedIndex=0;
		}
		if(e==11){
		document.getElementById("slc-solicita-au").disabled = false;
		document.getElementById("solicitar-au").style.display = "block";
		document.getElementById("solicitarC-au").style.display = "none";
		document.getElementById("slc-solicita-au").selectedIndex=0;
		}
		if(e==2){
		document.getElementById("slc-revisa").disabled = false;
		document.getElementById("revisar").style.display = "block";
		document.getElementById("revisarC").style.display = "none";
		document.getElementById("slc-revisa").selectedIndex=0;
		}
		if(e==12){
		document.getElementById("slc-revisa-au").disabled = false;
		document.getElementById("revisar-au").style.display = "block";
		document.getElementById("revisarC-au").style.display = "none";
		document.getElementById("slc-revisa-au").selectedIndex=0;
		}
		if(e==3){
		document.getElementById("slc-realiza").disabled = false;
		document.getElementById("realizar").style.display = "block";
		document.getElementById("realizarC").style.display = "none";
		document.getElementById("slc-realiza").selectedIndex=0;
		}
		if(e==13){
		document.getElementById("slc-realiza-au").disabled = false;
		document.getElementById("realizar-au").style.display = "block";
		document.getElementById("realizarC-au").style.display = "none";
		document.getElementById("slc-realiza-au").selectedIndex=0;
		}
		if(e==4){
		document.getElementById("slc-autoriza").disabled = false;
		document.getElementById("autorizar").style.display = "block";
		document.getElementById("autorizarC").style.display = "none";
		document.getElementById("slc-autoriza").selectedIndex=0;
		}
		if(e==14){
		document.getElementById("slc-autoriza-au").disabled = false;
		document.getElementById("autorizar-au").style.display = "block";
		document.getElementById("autorizarC-au").style.display = "none";
		document.getElementById("slc-autoriza-au").selectedIndex=0;
		}
	}

	async function generarOrden(idrequisicion){  
		var folio = document.getElementById('folio').value; 
		
		if(folio == idrequisicion){
			if(folio != ""){
				var contador = 0; 
				let fd = new FormData() 
				fd.append("id", "GenerarOrden")
				fd.append("idrequisicion", idrequisicion)
				fd.append("dptoid", props.dptoid)
				fd.append("usuariogenero", props.userid)
				
				for(var i=0;i<document.getElementsByName("producto").length;i++){   
				 	if(document.getElementsByName("checkbox")[i].checked == true){ 
						fd.append("productos[]", document.getElementsByName("descripcion")[i].value)
						fd.append("seleccionado[]", "1") 
						contador ++;
					}else{
						fd.append("productos[]", document.getElementsByName("descripcion")[i].value)
						fd.append("seleccionado[]", "0")
					}
				}
				 
				if(contador >=1){
					openModalLoad();
					const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
					closeModalLoad();
					getRequisiciones();
					//cleanForm();
					notify(res.data.trim());
					verRequisicion(idrequisicion); 
				} else {
					notify("Seleccione Almenos Una Opción");
				}

			} else {
				notify("Seleccione una requisición");
			}
		} else {
			notify("Click en ver está requisición para generar orden de compra");
		}
	}

	async function ActualizarReq(autorizado){ 
		let folio = document.getElementById('folio').value;
		let producto = document.getElementsByName('producto');
		let idproducto = document.getElementsByName('idproducto');
		let descripcion = document.getElementsByName('descripcion');
		let unidad = document.getElementsByName('unidad');
		let cantidad = document.getElementsByName('cantidad');
		let precio = document.getElementsByName('preciouni');
		let proveedor = document.getElementsByName('proveedor1');
		let precioCotizado = document.getElementsByName('precio1');
		let idcotizacion1 = document.getElementsByName('idcotizacion1');
		let observaciones = document.getElementById('observaciones').value;
		let identificador = document.getElementById('identificador').value;

		let fd = new FormData()
        fd.append("id", "ActuaizarReq")
        fd.append("folio", folio)
        fd.append("observaciones", observaciones.replaceAll("'", "´").replaceAll('"', "´´"))
        fd.append("identificador", identificador.replaceAll("'", "´").replaceAll('"', "´´"))
        fd.append("autorizado", autorizado)

        for(let i=0;i<document.getElementsByName("producto").length;i++){

			if(document.getElementsByName("checkbox")[i].checked == true){  
				fd.append("seleccionado[]", "1")  
			}else{ 
				fd.append("seleccionado[]", "0")
			}
            fd.append("producto[]", producto[i].value)  
            fd.append("descripcion[]", (descripcion[i].value).replaceAll("'", "´").replaceAll('"', "´´"))  
            fd.append("unidad[]", unidad[i].value)  
            fd.append("cantidad[]", cantidad[i].value)  
			fd.append("precio[]", precio[i].value.replaceAll(",", ""))  
			fd.append("idproducto[]", idproducto[i].value)   
        }
 
		for(var i=0;i<document.getElementsByName("producto").length;i++){   
			if(document.getElementsByName("checkbox")[i].checked == true){ 
			   fd.append("productos[]", document.getElementsByName("descripcion")[i].value)
			   fd.append("seleccionado[]", "1")
			   
		   }else{
			   fd.append("productos[]", document.getElementsByName("descripcion")[i].value)
			   fd.append("seleccionado[]", "0")
		   }
	   }

        for(let i=0;i<document.getElementsByName("proveedor1").length;i++){
            fd.append("proveedores[]", proveedor[i].value.replaceAll('"', '´´').replaceAll("'", "´"))  
            fd.append("precios[]", precioCotizado[i].value)       
            fd.append("idcotizacion1[]", idcotizacion1[i].value)       
        } 
		const res = await axios.post(process.env.REACT_APP_API_URL, fd);
		//reestablecer precios de la orden de compra (?)
			//
			if(res.data.trim() == "Error al actualizar, orden de compra ya generada"){
				if(window.confirm(res.data.trim() + ' ¿continuar?')){
					openModal(); 
				}

			}else{
				notify(res.data.trim());
			}
			
			
			
			if(res.data.trim() == "Error al actualizar, orden de compra ya generada"){

			}else{
				for(let i=0;i<document.getElementsByName("producto").length;i++){
					document.getElementsByName("producto")[i].value = "";
					document.getElementsByName("descripcion")[i].value = "";
					document.getElementsByName("unidad")[i].value = ""; 
					document.getElementsByName("cantidad")[i].value = "";
					document.getElementsByName("preciouni")[i].value = "";
				}
	

				for(let i=0;i<document.getElementsByName("proveedor").length;i++){
					try{
						document.getElementsByName("proveedor")[i].value = ""; 
						document.getElementsByName("precio")[i].value = "";      
						document.getElementsByName("idcotizacion1")[i].value = "";      
					}catch (Exception){

					}
					
				} 
	
				document.getElementById("observaciones").value = ""; 
				verRequisicion(folio);
				getRequisiciones();
			}
		
		

	}
	const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() { 
    setIsOpen(true); 
     }  
   

	function closeModal() {
		setIsOpen(false);
		}
		function closeModalFile() {
			setIsOpenFile(false);
			}


	async function CEU(){ 
		//alert("a");
		let contra = document.getElementById("artnoc").value; 
		let fd1 = new FormData() 
		fd1.append("id","C1") 
		fd1.append("CT",contra) 
		fd1.append("userid",props.userid) 
		const res1 = await axios.post(process.env.REACT_APP_API_URL, fd1);
		if(res1.data==1){
			//alert("autorizado");
		  ActualizarReq("1");
		  closeModal();
		}else{
		  closeModal();
		  return notify("CONTRASEÑA INCORRECTA"); 
		}
	} 

	 
	async function addCotizacion2(idcotizacion){
		setIdCotizacion(idcotizacion);
			document.getElementById("input-cotizacion").click(); 
	}
	 

	async function postFile2(){
		let fd = new FormData() 
		fd.append("id", "guardarCotizacion22")
		fd.append("idorden", id) 
		fd.append("idcotizacion", idcotizacion)
		fd.append("file", document.getElementById("input-cotizacion").files[0]) 

		let proveedor = document.getElementsByName('proveedor1');
		let precioCotizado = document.getElementsByName('precio1');
		let idcotizacion1 = document.getElementsByName('idcotizacion1');
		
		// se guarda proveedor, precio y cotizacion al mismo tiempo 
		for(let i=0;i<document.getElementsByName("proveedor1").length;i++){
			if(idcotizacion1[i].value == idcotizacion){
            fd.append("proveedor", proveedor[i].value.replaceAll('"', '´´').replaceAll("'", "´"))  
            fd.append("precio", precioCotizado[i].value)  
			}         
        } 


		
		const res = await axios.post(process.env.REACT_APP_API_URL,  fd, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		}); 
		verRequisicion(document.getElementById("folio").value);
		notify(res.data.trim());
	}


	async function getRequisiciones(){    
		//tipo usuario si 1 solo las del dpto si 2 todas las requisiciones 
		setLista([]);
		console.log("obtener requisiciones");
		var id = "GetRequisiciones";
		var date = document.getElementById("input-fecha").value; 
		var termino = document.getElementById("input-fecha-termino").value; 
		var folioreq = document.getElementById("input-folio-req").value;  
		var foliooc = document.getElementById("input-folio-oc").value; 
		var identificadorreq = document.getElementById("input-identificador-req").value; 
		var solicitareq = document.getElementById("buscar-solicita-rec").value; 
		var proveedorreq = document.getElementById("input-proveedor-req").value;
		var sinOrden = document.getElementById("sinOrden");
		let sinOrdenCompra = 0;
		if(sinOrden.checked==true){
			sinOrdenCompra = 1;
		}
		openModalLoad();
		const res = await axios.get(process.env.REACT_APP_API_URL+'?id='+id+'&date='+date+'&dptoid='+props.dptoid+'&tipo='+props.tipo+'&idu='+props.userid+'&termino='+termino+'&rzonsocial='+props.rzonsocial+'&folioreq='+folioreq+'&foliooc='+foliooc+'&identificadorreq='+identificadorreq+'&solicitareq='+solicitareq+'&proveedorreq='+proveedorreq+'&sinorden='+sinOrdenCompra+'&userdptoid='+props.userdptoid+'&supersu='+props.superus);
		closeModalLoad();
		 
		//var table = document.getElementById('productstable');
		
		 
		let res1;
		if(props.superus != 1){
		res1 = res.data.filter(item => (item.dptoidelabora == null) 
			//	|| (item.dptoid == props.userdptoid)
				//|| (item.dptoidelabora == props.userdptoid) // encargado (?) revisar
				|| (item.encargadodptoorigen == "1")
				|| (item.realizadptodestino == "1")
				|| (item.admindptodestino == "1")
				|| (item.encargadodptodestino  == "1")
				|| (item.userid == props.userid)
			);
		}else{
			res1 = res.data;
		}

		if(sinOrden.checked){
			console.log("sin orden");
			res1 = res1.filter(item => (item.ordencompra == null));
		}
		 
		console.log(res1);
		if(res.data.length >= 1){
			setLista(res1); 
		} else {
			//table.setAttribute("hidden", true);
		}
	}

  	async function ActualizarStatus(id, e){
			/*  GET ROW OF WHERE SELECT WAS CHANGED  */
		var rows = document.getElementsByClassName('id-orden');
		var rw;

		for(let element of rows){
			if (element.innerHTML == id){
				var tr = element.parentElement;
				var td = tr.getElementsByTagName('select')[0];
				rw = td.value;
			}
		}
		console.log(e.target.value);

		if(window.confirm('Quieres actualizar requisición con folio: ' + rw)){
			let fd = new FormData() 
			fd.append("id", "updateRequisicionGenerado")
			fd.append("idrequisicion", id)
			fd.append("nvoestado", e.target.value)
			const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
			
			notify(res.data.trim());
			getRequisiciones();
		}
	}

	 
	function seeFile(url){
		setIsOpenFile(true);
		setCurrentFile(url);
	}

	async function verRequisicion(idd){   

		let req = document.getElementById("requisiciones");
		let nvareq = document.getElementById("nvarequisicion");
		if(nuevaR == true){
			setNuevaR(false);
			setBtnText("Nueva requisición");
			 
			req.removeAttribute("hidden");
			nvareq.setAttribute("hidden", true);
		}else{
		 
		}


		var section = document.getElementById("requisiciones");
		setGenerada(0);
		setConOrden(0);
		setSurtida(0);
		setRecepcionConfirmada(0);
		setCancelada(0);
		

		try{
			document.getElementById("solicitarC").style.display = "none";
			document.getElementById("solicitarC-au").style.display = "none";
			document.getElementById("revisarC").style.display = "none";
			document.getElementById("revisarC-au").style.display = "none";
			document.getElementById("realizarC").style.display = "none";
			document.getElementById("realizarC-au").style.display = "none";
			document.getElementById("autorizarC").style.display = "none";
			document.getElementById("autorizarC-au").style.display = "none";
		} catch (Exception){}
		for(var i=0;i<document.getElementsByName("producto").length;i++){
			document.getElementsByName("producto")[i].value = "";
			document.getElementsByName("descripcion")[i].value = "";
			document.getElementsByName("unidad")[i].value = ""; 
			document.getElementsByName("cantidad")[i].value = "";
			document.getElementsByName("checkbox")[i].checked = false;
			document.getElementsByName("idproducto")[i].value = "";
		}

		for( i=0;i<document.getElementsByName("proveedor1").length;i++){ 
			document.getElementsByName("proveedor1")[i].value = ""; 
			document.getElementsByName("precio1")[i].value = "";  
			document.getElementsByName("idcotizacion1")[i].value = "";  
		}
		
		try{
			document.getElementById("solicitar").style.display = "block";
			document.getElementById("slc-solicita").disabled = false;
			document.getElementById("slc-solicita").selectedIndex=0;
			 
		} catch (Exception){}
		try{
			document.getElementById("solicitar-au").style.display = "block";
			document.getElementById("slc-solicita-au").disabled = false;
			document.getElementById("slc-solicita-au").selectedIndex=0;
		} catch (Exception){}
		try{
			document.getElementById("revisar").style.display = "block";
			document.getElementById("slc-revisa").disabled = false;
			document.getElementById("slc-revisa").selectedIndex=0;

		} catch (Exception){}
		try{
			document.getElementById("revisar-au").style.display = "block";
			document.getElementById("slc-revisa-au").disabled = false;
			document.getElementById("slc-revisa-au").selectedIndex=0;

		} catch (Exception){}
		try{
			document.getElementById("realizar").style.display = "block";
			document.getElementById("slc-realiza").disabled = false;
			document.getElementById("slc-realiza").selectedIndex=0;
		} catch (Exception){}
		try{
			document.getElementById("realizar-au").style.display = "block";
			document.getElementById("slc-realiza-au").disabled = false;
			document.getElementById("slc-realiza-au").selectedIndex=0;
		} catch (Exception){}
		try{
			document.getElementById("autorizar").style.display = "block";
			document.getElementById("slc-autoriza").disabled = false;
			document.getElementById("slc-autoriza").selectedIndex=0;
		} catch (Exception){}
		try{
			document.getElementById("autorizar-au").style.display = "block";
			document.getElementById("slc-autoriza-au").disabled = false;
			document.getElementById("slc-autoriza-au").selectedIndex=0;
		} catch (Exception){}
		
		document.getElementById("observaciones").value = ""; 
        var idrequisicion = idd; 
        var id = "GetDetalles";
		openModalLoad();
        const res = await axios.get(process.env.REACT_APP_API_URL+'?id='+id+'&idrequisicion='+idrequisicion);
        closeModalLoad();

		if(res.data[0].estado == "Generada"){
			setGenerada(25);
		}
		 if(res.data[0].estado == "Surtida"){
			setGenerada(25);
			setConOrden(25);
			setSurtida(25);
		}
		if(res.data[0].recepcion == "1"){
			setGenerada(25);
			setConOrden(25);
			setSurtida(25);
			setRecepcionConfirmada(25);
		}
	

		if(res.data[4].ordencompra != "0"){
			setGenerada(25);
			setConOrden(25);
		}

		if(res.data[0].estado == "Cancelada"){
			 
			setGenerada(0);
			setSurtida(0);
			setConOrden(0);
			setRecepcionConfirmada(0);
			setCancelada(100);
		}

		
		document.getElementById("departamento").value = res.data[0].departamento;
		document.getElementById("dptoid").value = res.data[0].dptoid;
		if(res.data[0].dptoidelabora == null || res.data[0].dptoidelabora == 0){
			document.getElementById("dptoidelabora").value = res.data[0].dptoid;
		}else{
			document.getElementById("dptoidelabora").value = res.data[0].dptoidelabora;
		}
        document.getElementById("fechacaptura").value = formatDate(res.data[0].fechacaptura);
        document.getElementById("solicita").value = res.data[0].solicita;
        document.getElementById("identificador").value = res.data[0].identificador;
        //document.getElementById("fecha").value = res.data[0].fecha;
        document.getElementById("observaciones").value = res.data[0].observaciones;
		document.getElementById("folio").value = res.data[0].folio;
		setCouno(res.data[0].couno);
		setCodos(res.data[0].codos);
		setCotres(res.data[0].cotres);
		
		console.log(res.data[1].length);

		if (props.tipo == "2") { 
			setbtn_Generar(idd);
		 
		}

		try{
			var tableHeaderRowCount = 2;
			var table = document.getElementById('requisicionprods');
			var rowCount = table.rows.length;

			for ( i = tableHeaderRowCount; i < rowCount; i++) {
				table.deleteRow(tableHeaderRowCount);
			}

			if(res.data[1].indexOf(1)){ 
				for( i=0;i<res.data[1].length-1;i++){
					var newrow = table.lastChild.cloneNode(true);
					newrow.firstChild.innerHTML = parseFloat(newrow.firstChild.innerHTML) + 1;
					table.appendChild(newrow);
				}

				for( i=0;i<res.data[1].length;i++){
					document.getElementsByName("producto")[i].value = res.data[1][i].producto;
					document.getElementsByName("descripcion")[i].value = res.data[1][i].descripcion; 
					document.getElementsByName("unidad")[i].value = res.data[1][i].unidad;
					document.getElementsByName("preciouni")[i].value = res.data[1][i].costouni;
					document.getElementsByName("idproducto")[i].value = res.data[1][i].idproducto;
					
					if(res.data[1][i].cantidad > 0){
						document.getElementsByName("cantidad")[i].value = res.data[1][i].cantidad;
					}

					if(res.data[1][i].seleccionado ==  "1"){
						document.getElementsByName("checkbox")[i].checked = true;
					}

					document.getElementsByName("btn-eliminar-prod")[i].setAttribute("id", res.data[1][i].idproducto);
					document.getElementsByName("btn-eliminar-prod")[i].addEventListener("click", (e) => eliminarProducto(e));
				}
			}  
		} catch (Exception){}	
	
		try{ 
			 setLProveedores([]);
			 if(res.data[2] != "" && res.data[2] != null){
				setLProveedores(res.data[2]);
			 }else{
				//let emptyArray = []; NO TERMINADO // AL ACTUALIZAR SI ID = INSERT ROW
				var emptyArray = [{
					'contador': 1,
					'documento': 2,
					'id': 3,
					'id4': 4
				}];
				
			 }
			 
		} catch (Exception){}

		try{
			//var textArea = document.getElementById("autorizartextarea"); 

			if(res.data.indexOf(3)){
				for( i=0;i<res.data[3].length;i++){
					if(res.data[3][i].tipo == "1"){
						let exists = 0;
						for (let a = 0; a < document.getElementById("slc-solicita").length; ++a){
							if (document.getElementById("slc-solicita").options[a].value == res.data[3][i].name){
							  exists = 1; 
							}
					 	}
						if(exists == 0){
							document.getElementById("slc-solicita").add(new Option(" "+res.data[3][i].name));
						}
						//document.getElementById("slc-solicita").add(new Option(" "+res.data[3][i].name));
						document.getElementById("slc-solicita").value =res.data[3][i].name;
						document.getElementById("slc-solicita").disabled = true;
						document.getElementById("solicitar").style.display = "none";
						document.getElementById("solicitarC").style.display = "block";
					}
					if(res.data[3][i].tipo == "11"){
						let exists = 0;
						for (let a = 0; a < document.getElementById("slc-solicita-au").length; ++a){
							if (document.getElementById("slc-solicita-au").options[a].value == " "+res.data[3][i].name){
							  exists = 1;
							}
						}
						if(exists == 0){
							document.getElementById("slc-solicita-au").add(new Option(" "+res.data[3][i].name));
						}
						//document.getElementById("slc-solicita-au").add(new Option(" "+res.data[3][i].name));
						document.getElementById("slc-solicita-au").value =res.data[3][i].name;
						document.getElementById("slc-solicita-au").disabled = true;
						document.getElementById("solicitar-au").style.display = "none";
						document.getElementById("solicitarC-au").style.display = "block";
					}

					if(res.data[3][i].tipo == "2"){
						let exists = 0;
						for (let a = 0; a < document.getElementById("slc-revisa").length; ++a){
							if (document.getElementById("slc-revisa").options[a].value == " "+res.data[3][i].name){
							  exists = 1;
							}
						}
						if(exists == 0){
							document.getElementById("slc-revisa").add(new Option(" "+res.data[3][i].name));
						}
						//document.getElementById("slc-revisa").add(new Option(" "+res.data[3][i].name));
						document.getElementById("slc-revisa").value =res.data[3][i].name;
						document.getElementById("slc-revisa").disabled = true;
						document.getElementById("revisar").style.display = "none";
						document.getElementById("revisarC").style.display = "block";
					}
					
					if(res.data[3][i].tipo == "12"){
						let exists = 0;
						for (let a = 0; a < document.getElementById("slc-revisa-au").length; ++a){
							if (document.getElementById("slc-revisa-au").options[a].value == " "+res.data[3][i].name){
							  exists = 1;
							}
						}
						if(exists == 0){
							document.getElementById("slc-revisa-au").add(new Option(" "+res.data[3][i].name));
						}
						
						document.getElementById("slc-revisa-au").value =res.data[3][i].name;
						document.getElementById("slc-revisa-au").disabled = true;
						document.getElementById("revisar-au").style.display = "none";
						document.getElementById("revisarC-au").style.display = "block";
					}

					if(res.data[3][i].tipo == "3"){
						
						let exists = 0;
						for (let a = 0; a < document.getElementById("slc-realiza").length; ++a){
							if (document.getElementById("slc-realiza").options[a].value == " "+res.data[3][i].name){
							  exists = 1;
							   
							}
						}
						if(exists == 0){
							document.getElementById("slc-realiza").add(new Option(" "+res.data[3][i].name));
						}
						 
						document.getElementById("slc-realiza").value = res.data[3][i].name;
						document.getElementById("slc-realiza").disabled = true;
						document.getElementById("realizar").style.display = "none";
						document.getElementById("realizarC").style.display = "block";
					}
					if(res.data[3][i].tipo == "13"){
						let exists = 0;
						for (let a = 0; a < document.getElementById("slc-realiza-au").length; ++a){
							if (document.getElementById("slc-realiza-au").options[a].value == " "+res.data[3][i].name){
							  exists = 1;
							}
						}
						if(exists == 0){
							document.getElementById("slc-realiza-au").add(new Option(" "+res.data[3][i].name));
						}
					
						document.getElementById("slc-realiza-au").value =res.data[3][i].name;
						document.getElementById("slc-realiza-au").disabled = true;
						document.getElementById("realizar-au").style.display = "none";
						document.getElementById("realizarC-au").style.display = "block";
					}
					
					if(res.data[3][i].tipo == "4"){
						let exists = 0;
						for (let a = 0; a < document.getElementById("slc-autoriza").length; ++a){
							if (document.getElementById("slc-autoriza").options[a].value == " "+res.data[3][i].name){
							  exists = 1;
							}
						}
						if(exists == 0){
							document.getElementById("slc-autoriza").add(new Option(" "+res.data[3][i].name));
						}
						//document.getElementById("slc-autoriza").add(new Option(" "+res.data[3][i].name));
						document.getElementById("slc-autoriza").value =res.data[3][i].name;
						document.getElementById("slc-autoriza").disabled = true;
						document.getElementById("autorizar").style.display = "none";
						document.getElementById("autorizarC").style.display = "block";
					}
					if(res.data[3][i].tipo == "14"){
						let exists = 0;
						for (let a = 0; a < document.getElementById("slc-autoriza-au").length; ++a){
							if (document.getElementById("slc-autoriza-au").options[a].value == " "+res.data[3][i].name){
							  exists = 1;
							}
						}
						if(exists == 0){
							document.getElementById("slc-autoriza-au").add(new Option(" "+res.data[3][i].name));
						}
						//document.getElementById("slc-autoriza-au").add(new Option(" "+res.data[3][i].name));
						document.getElementById("slc-autoriza-au").value =res.data[3][i].name;
						document.getElementById("slc-autoriza-au").disabled = true;
						document.getElementById("autorizar-au").style.display = "none";
						document.getElementById("autorizarC-au").style.display = "block";
					}
				} 
			}
		} catch (Exception){}
		section.scrollIntoView({ behavior: "smooth", block: "start" });
	}

	async function eliminarProducto(e){
		var folio = document.getElementById("folio").value;
		let idproducto = e.target.id;
		if(window.confirm('¿Eliminar producto?')){
			let fd = new FormData() 
			fd.append("id", "eliminarProductoRequisicion")
			fd.append("idproducto", idproducto)
			fd.append("idrequisicion", folio)
			const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
			
			notify(res.data.trim());
			//getRequisiciones();
			verRequisicion(folio);
		}
	}

	 

	function desactivarRequisicion(idreq){
		if(window.confirm('Eliminar requisicion: ' + idreq)){
		let toSend = new FormData();
		toSend.append("id", "deleteRequisicion"); 
		toSend.append("idreq", idreq);

		fetch(process.env.REACT_APP_API_URL, {
			method: "POST",
			mode: "cors",
			body: toSend
		})
		.then(response => response.text())
		.catch(error => alert(error))
		.then((data)=> {
			if(data){
				notify("Requisicion eliminada");
				getRequisiciones();
				cleanForm();
			}
		})
	}
	}


	function replicarRequisicion(idreq){
		if(window.confirm('Replicar requisicion: ' + idreq)){
		let toSend = new FormData();
		toSend.append("id", "replicarRequisicion"); 
		toSend.append("folio", idreq);

		fetch(process.env.REACT_APP_API_URL, {
			method: "POST",
			mode: "cors",
			body: toSend
		})
		.then(response => response.text())
		.catch(error => alert(error))
		.then((data)=> {
			if(data){
				notify("Requisicion replicada");
				getRequisiciones();
				cleanForm();
			}
		})
	}
	}

	function seleccionarTodo(){
		var checkbox = document.getElementsByName('checkbox'); 
		let checkb = document.getElementById("todos");
		if(checkb.checked == true){

			for(var i = 0; i<checkbox.length; i++){
				checkbox[i].checked = true;
			}
		}else{

			for(var i = 0; i<checkbox.length; i++){
				checkbox[i].checked = false;
			}
		}

	}

	function Agregar(){  
		var table = document.getElementById('requisicionprods');
	 
		var newrow = table.lastChild.cloneNode(true);
		newrow.firstChild.innerHTML = parseFloat(newrow.firstChild.innerHTML) + 1; 

		for (var i = 0; i <  newrow.getElementsByTagName("input").length; i++){
			var a = newrow.getElementsByTagName("input")[i];  
			a.setAttribute("id", "check"+i);
			a.value = "";
		//	a.setAttribute("hidden", true);
		}
		
		
		table.appendChild(newrow);  
		for (var i = 0; i <  document.getElementsByName("preciouni").length; i++){
			var a = document.getElementsByName("preciouni")[i]; 
			a.setAttribute("id", "input"+i);
		}
		
		for (var i = 0; i <  document.getElementsByName("checkbox").length; i++){
			var a = document.getElementsByName("checkbox")[i];  
		 	a.setAttribute("id", "check"+i);
			
			if(i + 1 == document.getElementsByName("checkbox").length){
				a.checked = false;
			}
			
		}
		

	}

	function nuevaRequisicion(){
		 

		let req = document.getElementById("requisiciones");
		let nvareq = document.getElementById("nvarequisicion");
		if(nuevaR == true){
			setNuevaR(false);
			setBtnText("Nueva requisición");
		 
			req.removeAttribute("hidden");
			nvareq.setAttribute("hidden", true);
		}else{
			setNuevaR(true);
		    setBtnText("Cancelar");
			//btn btn-outline-success btn-sm
			//btn btn-outline-danger btn-sm
			
			nvareq.removeAttribute("hidden");
			req.setAttribute("hidden", true);
		}
	}


	  


	 

	function cleanForm(){
		let input1 = document.getElementById("folio");
		let input2 = document.getElementById("departamento");
		let input3 = document.getElementById("fechacaptura");
		let input4 = document.getElementById("solicita");
		let input5 = document.querySelectorAll("[name='producto']");
		let input6 = document.querySelectorAll("[name='descripcion']");
		let input7 = document.querySelectorAll("[name='unidad']");
		let input8 = document.querySelectorAll("[name='cantidad']");
		let input9 = document.querySelectorAll("[name='preciouni']");
		let input10 = document.querySelectorAll("[name='proveedor']");
		let input11 = document.querySelectorAll("[name='precio']");
		let input12 = document.getElementById("observaciones");

		input1.value = null;
		input2.value = null;
		input3.value = null;
		input4.value = null;
		
		for (let item of input5){
			item.value = null;
		}
		for (let item of input6){
			item.value = null;
		}
		for (let item of input7){
			item.value = null;
		}
		for (let item of input8){
			item.value = null;
		}
		for (let item of input9){
			item.value = null;
		}
		for (let item of input10){
			item.value = null;
		}
		for (let item of input11){
			item.value = null;
		}

		input12.value = null;
	}

	const buttonVerRequisicion = (e, id) => {
		e.preventDefault();
		verRequisicion(id); 
	};
	const buttonEliminarRequisicion = (e, id) => {
		e.preventDefault();
		desactivarRequisicion(id); 
	};
	const buttonReplicarRequisicion = (e, id) => {
		e.preventDefault();
		replicarRequisicion(id); 
	};

	function formatDateFilter(date){
        let index = date.search(" ");
        date = date.substring(0, index);
       // date = date.split("-");
        let formatedDate = date[2] +"/"+ date[1] +"/"+ date[0];
        return(date);
      }

 /* 
 Columnas tabla
 */
	const columns = [
		{
			name: 'Folio',
			selector: row => row.idrequisicion,
			sortable: true, 
			width: "90px",
			
		},
		{
			name: 'Fecha',
			sortable: true,
			selector: row => formatDateFilter(row.fechacaptura),
		},
		{
			name: 'Solicita',
			selector: row => row.solicita,
			sortable: true,
			width: "280px",
		},
		{
			name: 'Productos',
			sortable: true,
			width: "180px",
			selector: row => row.identificador,
		},
		{
			name: 'Estado', 
			cell: (row) => {
				return (
					((row.estado == "Generada") && (props.tipo == "2")) ? 
								<select name={row.estado} onChange={(e) => ActualizarStatus(row.idrequisicion, e)}>
								<option>{row.estado}</option>
								<option>Cancelada</option>
								<option>Surtida</option>
								</select> :  <label>{row.estado}</label>
				)
			}
		},
		{
			name: 'Detalles', 
			cell: (row) => <button className='btn btn-outline-success btn-sm bttn-requisicion' 	onClick={(e) => buttonVerRequisicion(e, row.idrequisicion)}>Ver</button>,
				ignoreRowClick: true,
				allowOverflow: true,
				button: true,
		},
		{
			name: 'Orden',
			selector: row => row.ordencompra,
			width: "150px",
			sortable: true,
			cell: (row) => {
				return (
					 (row.ordencompra == null) ? 
						<td align='center' style={{backgroundColor: 'red', color: 'white'}}>
							<div onClick={() => solicitarElaboracion(row.dptoidelabora, row.identificador)}>Pendiente <FaBell /></div>
							{/*(props.tipo != "2") ? <>
								
							</> :
							<button  className='Bttns' hidden  type="button" onClick={() => generarOrden(row.idrequisicion)}>Generar</button>
				*/}
						</td> : 
						<td onClick={() => props.verOrden(row.idrequisicion, row.ordencompra)} style={{width:'130px'}}>Generada ({row.ordencompra}) <FaExternalLinkSquareAlt style={{color:props.defaultColor}}/></td>
						
				)
			}
		},
		{
			name: 'Recepcion',
			selector: row => row.recepcion,
			width: '150px',
			cell: (row) => { 
				return ((row.recepcion == 1) ? 
				<td>Confirmada</td>
				:
					(row.userid == props.userid) ?
					<td><button className='btn btn-outline-success btn-sm' onClick={ () => confirmarRecepcion(row.idrequisicion)}>Confirmar</button></td>
					:
					<td>Pendiente</td>
				)
				}
		},
		{
			name: 'Eliminar',
			cell: (row) => <button className='btn btn-outline-danger btn-sm' 	onClick={(e) => buttonEliminarRequisicion(e, row.idrequisicion)}>Eliminar</button>,
				ignoreRowClick: true,
				allowOverflow: true,
				button: true,
		},
		{
			name: 'Replicar',
			cell: (row) => <button className='btn btn-outline-success btn-sm' 	onClick={(e) => buttonReplicarRequisicion(e, row.idrequisicion)}><FaShare /></button>,
				ignoreRowClick: true,
				allowOverflow: true,
				button: true,
		},
	];


	async function solicitarElaboracion(dptoidelabora, identificador){
		if(window.confirm('Enviar notificación?')){
			openModalLoad(); 
			let fd = new FormData()
			fd.append("id", "solicitarElaboracion") 
			fd.append("dptoidelabora", dptoidelabora) 
			fd.append("identificador", identificador) 
			fd.append("nombresolicita", props.name) 
			const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
			//notify(res.data);
			notify("Notificación enviada correctamente");
			closeModalLoad();
		}
	}

	 

	const conditionalRowStyles = [
			{
				when: row => row.ordencompra < 1,
				style: {
								backgroundColor: 'rgba(242, 38, 19, 0.9)',
								color: 'white',
								'&:hover': {
									cursor: 'not-allowed',
								},
				},
			}
		];
		
	return (
		<div className="container ">
			<input id='input-cotizacion' type='file' class="input-hidden" onChange={()=> postFile2()}></input>
			 
			<Nabvar defaultColor={props.defaultColor}  idd="navrequisiciones" titulo="Requisiciones" departamento={props.rzonsocial} dptoid={props.dptoid}/>    

			<div className="row p-3">
				<div>
					<span>Filtrar por fecha &nbsp; </span>
					<input id='input-fecha' type='date' class="filter-date"  onChange={() => getRequisiciones()}></input>
					<span>&nbsp; </span>
					<input id='input-fecha-termino' type='date' class="filter-date"  onChange={() => getRequisiciones()}></input>
					<span>&nbsp; &nbsp; </span>
					<button class="btn btn-outline-success btn-sm" onClick={() => getRequisiciones()}>Filtrar <FaSyncAlt/></button>
				</div> 
				<div style={{display: 'flex', flexDirection: 'row'}}>
				
				<div>
                    <span>Folio: &nbsp;&nbsp;</span> <br></br>
                    <input  id='input-folio-req' type='number' class="input-filter"  onChange={() => getRequisiciones()}></input>
                    <span>&nbsp;</span> <span>&nbsp;</span> 
					<button hidden class="btn btn-outline-success btn-sm" onClick={() => getRequisiciones()}>Filtrar <FaSyncAlt/></button>
                    </div>

				<div>

					
				</div>

					
				<div>
                    <span>Orden de compra: &nbsp;&nbsp;</span> <br></br>
                    <input  id='input-folio-oc' type='number' class="input-filter"  onChange={() => getRequisiciones()}></input>
                    <span>&nbsp;</span> <span>&nbsp;</span> 
					<button hidden class="btn btn-outline-success btn-sm" onClick={() => getRequisiciones()}>Filtrar <FaSyncAlt/></button>
                    </div>
					<div hidden>
					<span>Proveedor: &nbsp;&nbsp;</span><br></br>
                    <input  id='input-proveedor-req' type='text'  onKeyUp={(e) => filtrarPorProveedor1(e)} style={{width:'337px',fontSize:'12px', cursor:'pointer'}} ></input> <span>&nbsp;</span> <span>&nbsp;</span> <br></br>
                    <select class="slc-b"   id="buscar-pro-req" onChange={() => proveedorSelected1()}>
                                          
                                            {listaProveedores.map(item => ( 
                                            <option  value={item.proveedor}> {item.proveedor}</option>))
                                            }   
                                        </select>
                    <span>&nbsp;</span>  
					</div>

					<div>
                    <span>Identificador: &nbsp;&nbsp;</span><br></br>
                    <input  id='input-identificador-req' type='text'   style={{width:'337px',fontSize:'12px', cursor:'pointer'}} ></input><span>&nbsp;</span> <span>&nbsp;</span> <br></br>
                    
                    <span>&nbsp;</span> 
 
                    </div>

					<div>
                    <span>Solicita: &nbsp;&nbsp;</span><br></br>
                    <select  style={{width:'337px',fontSize:'12px', cursor:'pointer', height: '23px'}}  id="buscar-solicita-rec" >
                    <option  value=""></option>
                                          {props.usuarios.map(item => ( 
                                          <option  value={item.userid}> {item.name}</option>))
                                          }   
                                      </select>
                    <span>&nbsp;</span> 

					
                </div>
				<div>

				<span>Orden de compra por generar: &nbsp;&nbsp;</span><br></br>
					<input type='checkbox' id="sinOrden"></input>
                    </div>
 
				
				
				</div>

					
                
               

				  <br></br> 
				<div id='div-requisiones' class="lista-registros-req">
				<DataTableExtensions
			columns={columns}
			data={lista}
			print={true}
			export={false} 
			filterPlaceholder={"Filtrar"}
			>
			<DataTable
						columns={columns}
						data={lista}
						fixedHeader={true}
						fixedHeaderScrollHeight={'100%'}
						pagination
						customStyles={tableCustomStyles}
					//	conditionalRowStyles={conditionalRowStyles}
						highlightOnHover={true}
						noDataComponent={"No hay registros para mostrar"}
					/>
			</DataTableExtensions>
				
					<table id="productstable-requisiones" class="width-100" hidden>
						<tr class="sticky-header">
							<th hidden></th>
							<th>Folio</th>
							<th>Fecha</th>
							<th>Solicita</th>
							<th>Productos</th>
							<th>Estado</th>
							<th >Detalles</th>
							
							{(props.tipo == "2") ? <th>Orden</th> : <th>Orden</th> }

							<th >Recepción</th>
							<th>Eliminar</th>
							<th>Replicar</th>
						</tr>
						 
					 
						{ lista.map(item => ( 
						<>
							  {((item.dptoidelabora == null)
							  ||(item.dptoid == props.userdptoid) 
							  || (item.dptoidelabora == props.userdptoid)
							  || (item.encargadodptoorigen == "1") 
							  //|| (item.realizadptoorigen == "1")
							  || (item.encargadodptoorigen == "1")
							  || (item.realizadptodestino == "1")
							  || (props.superus == "1")
							  || (item.admindptodestino == "1")
							  || (item.admindptodestino == "1")
							  || (item.userid == props.userid)
							  ) ?  
							  <tr  class="font-14 table-row tabletr"   >
 
                        <td hidden><label class="add-bttn" >+</label></td>
						<td className='id-orden'>{item.idrequisicion}</td>
							<td>{ formatDate(item.fechacaptura) }</td>
							<td>{item.name}</td>
						    <td>{item.producto + " " + item.identificador}</td>	
							<td>
								{((item.estado == "Generada") && (props.tipo == "2")) ? 
								<select name={item.estado} onChange={() => ActualizarStatus(item.idrequisicion)}>
								<option>{item.estado}</option>
								<option>Cancelada</option>
								<option>Surtida</option>
								</select> :  <label>{item.estado}</label>}
							</td>
							<td ><button   type="button" className='btn btn-outline-success btn-sm bttn-requisicion' onClick={() => verRequisicion(item.idrequisicion)} >Ver</button></td>

							{ (item.ordencompra == null) ? 
							<td>
								{(props.tipo != "2") ? <label></label> :
								<button  className='Bttns' hidden  type="button" onClick={() => generarOrden(item.idrequisicion)}>Generar</button>
								}
							</td> : <td onClick={() => props.verOrden(item.idrequisicion, item.ordencompra)} style={{width:'130px'}}>Generada ({item.ordencompra}) <FaExternalLinkSquareAlt style={{color:props.defaultColor}}/></td>
							} 
							{ (item.recepcion == 1) ? 
							<td>Confirmada</td>
							:
								(item.userid == props.userid)?
								<td><button className='btn btn-outline-success btn-sm' onClick={ () => confirmarRecepcion(item.idrequisicion)}>Confirmar</button></td>
								:
								<td>Pendiente</td>
							}
							<td>
								<button className='btn btn-outline-danger btn-sm' onClick={ () => desactivarRequisicion(item.idrequisicion) }>Eliminar</button>
							</td>
							<td align="center" >
								<button className='btn btn-outline-success btn-sm' onClick={ () => replicarRequisicion(item.idrequisicion) }><FaShare /></button>
							</td>
						</tr> 
                        : 
                        <></>
                         }
						 </>
							
						))}		 
					</table> 
				</div>

				

                <div class="margin-auto" > 
					
				<br></br>
				<br></br>
				<div clas="width-100"  align="left">
					<button class="btn btn-outline-success btn-sm" onClick={() => nuevaRequisicion()}>{btnText}</button>
				</div> 
				
					{/* Empieza requisición*/}

					
					{/* Termina requisición*/}

					<div id="requisiciones">
					<br></br> 
					<ProgressBar>
						<ProgressBar striped animated variant="success" label={'Generada'} now={generada} key={1} />
						<ProgressBar striped animated variant="success" label={'Orden de compra generada'} now={conorden} key={2} />
						<ProgressBar striped animated variant="success" label={'Surtida'} now={surtida} key={3} />
						<ProgressBar striped animated variant="success" label={'Recepción confirmada'} now={recepcionconfirmada} key={4} />
						<ProgressBar striped animated variant="danger" label={'Cancelada'} now={cancelada} key={5} />
    				</ProgressBar>

					<br></br> 
					<div  style={{backgroundColor:'white', border:'none', borderRadius:'5px', width:'100%', margin:'auto', padding:'12px', boxShadow: 'rgb(0 0 0 / 45%) 0px 5px 15px'}}>
					{/*Empieza formato encabezado orden de compra */}
					<br></br>  
					<div style={{display: 'flex', justifyContent: 'space-between'}}>
				 
                            <div class="div-section2">
                                <img id="logoOrden" src={"data:image/png;base64,"+props.datose.logoblob}  alt="LOGO" style={{width: '160px', height: 'auto', paddingLeft:'25px'}}></img>
                                <span><b class="font-25">REQUISICIÓN&nbsp;&nbsp;&nbsp;&nbsp;</b></span>
                            </div>
            
                            <div class="div-section2">
                                <span><b class="font-30" id="rzonsocial">{props.datose.name}</b></span>
                                <span><b id="direccion">{props.datose.direccion}</b></span> 
                                <span><b>Tel. </b><b id="telempresa">{props.datose.telefono}</b></span>
                            </div>

                            <div class="div-section2">
                                <table class="border-line" >
                                    <tr align="center">
                                        <th class="cell-1">Folio</th> 
                                    </tr>
                                    <tr>
                                        <td>
                                            <input id="folio" readOnly="readonly" class="input-1"></input>
                                            <input id="idrequisicion" readOnly="readonly" class="input-1"hidden></input>
                                        </td> 
                                          
                                    
                                    </tr>
                                </table>
                                <br></br>
                                <table class="border-line">
                                    <tr align="center">
                                        <th class="cell-1">Fecha</th> 
                                    </tr>
                                    <tr>
                                        <td>
                                            <input id="fechacaptura" readOnly='readonly' class="input-1"></input> 
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
						{/*
						Termina encabezado de diseño orden de compra
						*/}
						<table class="width-100"> 
							 

							<tr>
								 <td hidden="hidden">
									<label style={{fontFamily:'Roboto, sans-serif'}}>Solicita:</label>
									<br></br>
									<input id="departamento" readonly="readonly" style={{width:'530px', height:'auto'}} ></input>
								</td>
								  
								<td>
									<label>Solicita:</label>
									<br></br>  
									<select id="dptoid"  class="select-req"  onChange={(e) => actualizarDepartamento(e.target.value, "1")}  >
										{ (props.dptos1).map(item => ( 
										<option value={item.dptoid}> {item.name}</option>))
										}  
									</select> 
								</td> 
								<td>
									<label>Elabora:</label>
									<br></br> 
									<select id="dptoidelabora"  class="select-req" onChange={(e) => actualizarDepartamento(e.target.value, "2")}   >
								 		{ (props.dptos1).map(item => ( 
										<option value={item.dptoid}> {item.name}</option>))
										}  
									</select> 
								</td> 
							</tr>
							 
							<tr>
								<td>
									<label style={{fontFamily:'Roboto, sans-serif'}}>Solicitado por:</label><br/>
									<input id="solicita" readonly="readonly"  class="inpt-nva-req" />
								</td>  
								<td>
									<label>Identificador:</label>
									<br/>
									<input  class="inpt-nva-req" id="identificador"/>
								</td>  
							</tr>  
							<tr></tr>  
						</table>	
						
						<br/>

						<table id='requisicionprods' class="width-100"> 
							<tr>
								<th></th>  
								<td class="cell-productos" hidden="hidden"><label>Producto</label></td>
								<td class="cell-productos"><label>Descripción</label></td>
								<td class="cell-productos"><label>Unidad</label></td>
								<td class="cell-productos"><label>Cantidad</label></td>
								<td class="cell-productos"><label>Precio</label></td>
								<td class="cell-productos"><input type="checkbox" onChange={() => seleccionarTodo()} id="todos"/></td>
							</tr>

							<tr class="width-100">
								<td class="width-2">1</td>
								<td hidden="hidden"><input name="producto"  style={{width:'162px',height:'auto'}}/></td> 
								<td  style={{width:'61%'}}> <input name="descripcion"  class="inpt-nva-req"/></td>
								<td class="width-10"> 
 
								<select name="unidad"  class="select-req-1">
											{props.unidades.map(item => ( 
                                            <option  value={item.unidad}>{item.unidad}</option>))
                                            }  
								</select>
								</td> 
								<td class="width-10">  <input name="cantidad"  class="width-100" style={{height:'auto'}} /></td>
								<td class="width-10"> <input name="preciouni"  class="inpt-nva-req" /></td>
								<td class="width-2"> <input type='checkbox' name="checkbox"></input> </td>
								<td class="width-2"><button name="btn-eliminar-prod" class="btn btn-outline-danger btn-sm "><FaTrash class="color-red" /></button></td>
								<td hidden="hidden"> <input name="idproducto"></input> </td>
								
							</tr> 
						</table>	
						<div class="addButton">
							<img onClick={() => Agregar()} alt="Agregar" src={add} className='btn btn-outline btn-sm padding2 bggreen'  ></img>
					 		</div>  
						
						<br/>
						<table >
						<tr class="width-100" style={{minWidth:'954px'}}> 
							
								<td class="width-2"></td>
								<td class="width-88">Proveedor</td>
								<td class="width-20">Precio cotizado</td>
								<td class="width-10">Cotización</td>
							</tr>
						{proveedores.map(item => ( 
                                            <tr class="width-100" style={{width:'954px'}}>
												<td class="width-2">{item.contador}</td>
												<td class="width-68"> 
												<input  name="proveedor1" id={"proveedor1"+item.id}  onKeyUp={() => filtrarPorProveedor(item.id)} class="inpt-nva-req" defaultValue={item.proveedor} style={{cursor:'pointer'}}></input>
													<select  id={"pro1"+item.id} class="slc-b" onChange={() => proveedorSelected(item.id)}>
														{proveedor.map(item => ( 
														<option  value={item.proveedor}> {item.proveedor}</option>))
														}   
													</select>
													<br></br> 
												</td> 
												<td class="width-20"><input name="precio1"  class="inpt-nva-req" defaultValue={item.precio}></input></td> 
												<td hidden><input name="idcotizacion1"  style={{width:'132px',height:'auto'}} value={item.idcotizacion} /></td> 
												   
												{(item.documento == null) ? 
								<td align="center" class="width-10"><button   className='btn btn-outline-success btn-sm' onClick={()=>addCotizacion2(item.idcotizacion)}>Añadir</button></td>  :
								<td align="center" class="width-10"><a  href={process.env.REACT_APP_API_URL_COTIZACIONES+item.documento} target="_blank">Ver</a>
								   <span>&nbsp; &nbsp; </span>
                            <FaTrash class="color-red" onClick={() => removerCotizacionFCotizaciones(item.idcotizacion)}/>
                      </td>
								}        
											</tr>
											))
                                            }   
						</table>
						 
						<br/>
						
						<table class="width-100">
							<tr>
								<th></th>
								<td>Observaciones</td>
							</tr>

							<tr>
								<td></td>
								<td> 
									<textarea id="observaciones"  rows="2" class="width-100"  style={{ height:'140px',  resize: 'none'}}></textarea>
								</td> 
							</tr> 
							<tr>
								<td class="width-100" colspan="2" align='right'>
									<button class='btn btn-outline-success btn-sm' onClick={(e) => ActualizarReq("0")} >Actualizar</button>
								</td>
							</tr>
						</table>
						

						{(props.tipo != "2") ? 
						<label></label>:
						
						<div id="div-firmas">
							<table class="width-100">

			    				<tr>
										<th></th>
										<td>Solicita</td>
										<td>Revisa</td>
										<td>Revisa</td>
										<td>Autoriza</td>
								</tr>

								<tr>
										<td></td>
										<td> 
										<select id="slc-solicita" class="select-req-1">
										<option> Seleccione</option> 
											{ props.usuarios.map(item => ( 
											<option> {item.name}</option>    
											))}  
										</select> 	
										<button id="solicitar" className="btn btn-outline-success btn-sm" onClick={(e) => addPersona("1")} >Agregar <FaCheckCircle /></button> 
										<button id="solicitarC" class="btncancelar" onClick={(e) => CancelarAutoriza('1')} style={{display:"none",color:"black", fontSize: '.875rem', height:"30px", borderColor:"red", borderRadius:"5px",borderWidth:"1px"}}>Cancelar <FaTimesCircle/></button> 
										</td>	
										<td> 
										<select id="slc-revisa" class="select-req-1">
										<option> Seleccione</option> 
											{ props.usuarios.map(item => ( 
											<option> {item.name}</option>    
											))}  
										</select> 
										
										<button id="revisar" className="btn btn-outline-success btn-sm" onClick={(e) => addPersona("2")} >Agregar <FaCheckCircle /></button> 
										<button id="revisarC" class="btncancelar" onClick={(e) => CancelarAutoriza('2')} style={{display:"none",color:"black", fontSize: '.875rem', height:"30px", borderColor:"red", borderRadius:"5px",borderWidth:"1px"}}>Cancelar <FaTimesCircle /></button> 
										</td>	
										<td> 
										<select id="slc-realiza" class="select-req-1">
										<option> Seleccione</option> 
											{ props.usuarios.map(item => ( 
											<option> {item.name}</option>    
											))}  
										</select> 
										
										<button id="realizar" className="btn btn-outline-success btn-sm" onClick={(e) => addPersona("3")} >Agregar <FaCheckCircle /></button> 
										<button id="realizarC" class="btncancelar" onClick={(e) => CancelarAutoriza('3')} style={{display:"none",color:"black", fontSize: '.875rem', height:"30px", borderColor:"red", borderRadius:"5px",borderWidth:"1px"}}>Cancelar <FaTimesCircle /></button> 
									
										</td>	
										<td> 
										<select id="slc-autoriza" class="select-req-1">
										<option> Seleccione</option> 
											{ props.usuarios.map(item => ( 
											<option> {item.name}</option>    
											))}  
										</select> 

										<button id="autorizar" className="btn btn-outline-success btn-sm" onClick={(e) => addPersona("4")} >Agregar <FaCheckCircle /></button> 
										<button id="autorizarC" class="btncancelar" onClick={(e) => CancelarAutoriza('4')} style={{display:"none",color:"black", fontSize: '.875rem', height:"30px", borderColor:"red", borderRadius:"5px",borderWidth:"1px"}}>Cancelar <FaTimesCircle /></button> 
									
										</td>	
										 
								</tr>
								<br></br>
								<tr>
										<th></th>
										<td>Solicita en ausencia</td>
										<td>Revisa en ausencia</td>
										<td>Revisa en ausencia</td>
										<td>Autoriza en ausencia</td>
								</tr>

								<tr>
										<td></td>
										<td> 
										<select id="slc-solicita-au" class="select-req-1">
										<option> Seleccione</option> 
											{ props.usuarios.map(item => ( 
											<option> {item.name}</option>    
											))}  
										</select> 
										<button id="solicitar-au" className="btn btn-outline-success btn-sm" onClick={(e) => addPersona("11")} >Agregar <FaCheckCircle /></button> 
										<button id="solicitarC-au" class="btncancelar" onClick={(e) => CancelarAutoriza('11')} style={{display:"none",color:"black", fontSize: '.875rem', height:"30px", borderColor:"red", borderRadius:"5px",borderWidth:"1px"}}>Cancelar <FaTimesCircle /></button> 
										</td>	
										<td> 
										<select id="slc-revisa-au" class="select-req-1">
										<option> Seleccione</option> 
											{ props.usuarios.map(item => ( 
											<option> {item.name}</option>    
											))}  
										</select> 
										
										<button id="revisar-au" className="btn btn-outline-success btn-sm" onClick={(e) => addPersona("12")} >Agregar <FaCheckCircle /></button> 
										<button id="revisarC-au" class="btncancelar" onClick={(e) => CancelarAutoriza('12')} style={{display:"none",color:"black", fontSize: '.875rem', height:"30px", borderColor:"red", borderRadius:"5px",borderWidth:"1px"}}>Cancelar <FaTimesCircle /></button> 
										</td>	
										<td> 
										<select id="slc-realiza-au" class="select-req-1">
										<option> Seleccione</option> 
											{ props.usuarios.map(item => ( 
											<option> {item.name}</option>    
											))}  
										</select> 
										
										<button id="realizar-au" className="btn btn-outline-success btn-sm" onClick={(e) => addPersona("13")} >Agregar <FaCheckCircle /></button> 
										<button id="realizarC-au" class="btncancelar" onClick={(e) => CancelarAutoriza('13')} style={{display:"none",color:"black", fontSize: '.875rem', height:"30px", borderColor:"red", borderRadius:"5px",borderWidth:"1px"}}>Cancelar <FaTimesCircle /></button> 
									
									</td>	
									<td> 
										<select id="slc-autoriza-au" class="select-req-1">
										<option> Seleccione</option> 
											{ props.usuarios.map(item => ( 
											<option> {item.name}</option>    
											))}  
										</select> 

										<button id="autorizar-au" className="btn btn-outline-success btn-sm" onClick={(e) => addPersona("14")} >Agregar <FaCheckCircle /></button> 
										<button id="autorizarC-au" class="btncancelar" onClick={(e) => CancelarAutoriza('14')} style={{display:"none",color:"black", fontSize: '.875rem', height:"30px", borderColor:"red", borderRadius:"5px",borderWidth:"1px"}}>Cancelar <FaTimesCircle /></button> 
									
									</td>	
										 
								</tr>
			

								  <br></br>
								<tr>
									<td></td>
									<td>

									{(btn_Generar==null)?
										<button disabled={'disabled'}>Generar</button>:
										<button className={'btn btn-outline-success btn-sm'} onClick={(e) => generarOrden(btn_Generar)}>Generar <FaCheckCircle /></button>
									}		
									</td>
								</tr>
							</table>

						</div>
						}
					</div>
					</div>
					
					<NvaRequisicion proveedores={props.proveedores} proveedores1={props.proveedores1} datose={props.datose} unidades={props.unidades} nuevaRequisicionGuardada={nuevaRequisicionGuardada}  dptos1={props.dptos1} defaultColor={props.defaultColor} departamentoid={props.departamentoid} tipo={props.tipo} departamento={props.departamento} dptoid={props.dptoid} userid={props.userid} usuario={props.usuario} name={props.name} rzonsocial={props.rzonsocial} />

					 


				</div>
			</div>
		
		<ModalCarga modalIsOpenLoad={modalIsOpenLoad} defaultColor={props.defaultColor} closeModalLoad={closeModalLoad}/>    
 		
        <Toast/>    

<Modal
        isOpen={modalIsOpen} 
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
       
      >
         <h3>Editar requisición</h3>
          <label  class="label-1">Ingrese Contraseña</label>
        <br></br>
        <br></br>
        <input class="width-100" id="artnoc" type="password"></input>
        <br></br>
        <br></br>
  
<br></br>
		<button onClick={closeModal} class="btn btn-outline-danger btn-sm ">Cancelar</button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <button onClick={() => CEU()} class="btn btn-outline-success btn-sm ">Aceptar</button>
       </Modal>


	   
<Modal
        isOpen={modalIsOpenFile} 
        onRequestClose={closeModalFile}
        style={customStyles} 
       
      >
         <FileViewer
        fileType={"pdf"}
        filePath={currentFile}  />
		   </Modal>
		  
		</div>

		
	);   
}

export default Requisiciones;
