import React,{useState, useEffect} from 'react';  
import {Nabvar} from './component/Navbar'; 
import {ModalCarga, Toast} from './component/modalCarga'; 
import Ordenpago from './component/Ordenpago';
import {FaHooli, FaPrint, FaSyncAlt, FaChartPie} from 'react-icons/fa' 
import NvaOrdenpago from './component/NvaOrdenpago';
import Ordenpago1 from './component/Ordenpago1';
import NvaOrdenpago1 from './component/NvaOrdenpago1';
import axios from '../node_modules/axios';  
import html2canvas from 'html2canvas';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {jsPDF} from "jspdf";
import ProgressBar from 'react-bootstrap/ProgressBar';
import Modal from 'react-modal';
import { Chart } from "react-google-charts";
import './App.css'; 
import {formatNumber} from './commonFunctions';
import { formatDate} from './formatDate';
import tableCustomStyles from './tableCustomStyles';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";  
import { ThreeDots } from  'react-loader-spinner'
import customStyles from './component/modalCustomStyles'; 
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from 'react-data-table-component';

export default function OrdPago(props){
    //console.log("op-anombre-- "+document.getElementById("op-anombre").value);
    let subtitle; 
    const [modalIsOpenGenerar, setIsOpenGenerar] = React.useState(false);
    const [modalIsOpenGrafica, setIsOpenGrafica] = React.useState(false);
    const [value, setValue] = useState([]); 
	const [modalIsOpenLoad, setIsOpenLoad] = React.useState(false); 
    const [logoOP, setLogoOP] =  useState([]);  
    const [rzonsocialEmpresa, setrzonsocialEmpresa] = useState([]); 
    const [listadocumentos, setListaDocumentos] =  useState([]);
	const [modalIsOpenArchivo, setIsOpenArchivo] = React.useState(false);
    const [chartData, setChartData] =  useState([]);  
    const [totales, setTotales] =  useState([]);    
    const [modalIsOpenPagada, setIsOpenPagada] = React.useState(false); 
    const [ordennP, setordenP] = React.useState(false);

    const [folioActividad1, setFolioActividad1] = useState([]); 
    const [proveedor, setProveedor] =  useState([]); 
	
	const [listaProveedores1, setListaProveedores1] = useState([]); 

    //estados
    const [generada, setGenerada] = React.useState([]); 
    const [surtida, setSurtida] = React.useState([]); 
    //cancelada
    const [estadocancelada, setEstadoCancelada] = React.useState([]);

    function openModalLoad() {  
    setIsOpenLoad(true);  
     }  
   
     function closeModalLoad() { 
    setIsOpenLoad(false); 
     } 
     function notify(message){
        toast(message);
    }

    function openModal2() {
        setIsOpenGenerar(true);
      }
      function openModal3() {
        setIsOpenGrafica(true);
      }
  
     
    function afterOpenModalGenerar() {
        // references are now sync'd and can be accessed.
      
    }


    function agregarDoc(folio){
		openModalA();

		setFolioActividad1(folio);
		getDocumentos(folio);


	}

    function openModalA() {
        setIsOpenArchivo(true);
      }
      function closeModalA() {
        setIsOpenArchivo(false);
      }
      function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
        }
      function afterOpenModalA() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = props.defaultColor; 
      }
    function closeModalGenerar() {
        setIsOpenGenerar(false);
    }
   function closeModalGrafica() {
    setTotales([]);
    setOrdenesPagoCategoria([]);
        setIsOpenGrafica(false);
    }
  
	 
    const [ordenesPago, setOrdenesPago] =  useState([]);
    const [ordenesPagoCategoria, setOrdenesPagoCategoria] =  useState([]);

    const options = {
        chart: {},
        chartArea: { backgroundColor: 'transparent' },
        bar: {color: '#76A7FA'},
        backgroundColor: 'transparent',
        strokeColor:'#76A7FA', 
        fontSize:14,
        annotations: {
            textStyle: {
              fontName: 'Times-Roman',
              fontSize: 12
            }
        },
        hAxis: { title: 'Reporte de compras',fontSize: 12 },
      };
     

    useEffect(() =>{

        if(props.cargarDefaultOP != "" ){
			verOrdenPago(props.cargarDefaultOP, "");
		}
        getOrdenesPago(); 
        getUsuarios();
        getDatosEmpresa(); 
        getProveedores();
        
    } 
    , [])
    async function getUsuarios(){
		var id = "getUsuarios";
		const rese = await axios.get(process.env.REACT_APP_API_URL+'?id='+id); 
		console.log(rese.data);
		setValue(rese.data);      
	}
    async function getDocumentos(folio){
		var id = "getDocumentos";
		setListaDocumentos([]);
		const rese = await axios.get(process.env.REACT_APP_API_URL+'?id='+id+'&folio='+folio); 
		//console.log(rese.data);
		setListaDocumentos(rese.data);    
	}
	
    async function getProveedores(){
        var id = "getProveedores";
        const rese = await axios.get(process.env.REACT_APP_API_URL+'?id='+id);  
        setProveedor(rese.data);  
		setListaProveedores1(rese.data);
		
    }

    function filtrarPorProveedor(select, input){
		var selProveedores = document.getElementById(select);
		var currentInput = document.getElementById(input);
		var result = listaProveedores1.filter(item => item.proveedor.toLowerCase().includes(currentInput.value.toLowerCase()))
		console.log(result);
		 setProveedor(result);
		 
		if(currentInput.value.toLowerCase() == "" || result.length < 1){
		 selProveedores.style.display = "none";
		}else{
		 selProveedores.style.display = "block";
		 selProveedores.selectedIndex = -1;
		 selProveedores.size = 5;
		 var my_index = 100;
		 selProveedores.style.zIndex = my_index++;
		}
	 }
    function proveedorSelected(select, input){
		document.getElementById(input).value = document.getElementById(select).value; 
		document.getElementById(select).style.display = "none"; 
	}

 
    async function getDatosEmpresa(){
        var id = "getDatosEmpresa";
        var rzonsocial = props.rzonsocial;

        const rese = await axios.get(process.env.REACT_APP_API_URL+'?id='+id+'&rzonsocial='+rzonsocial);  
        console.log(process.env.REACT_APP_API_URL+'?id='+id+'&rzonsocial='+rzonsocial);
   
        try{
            
            console.log(rese.data); 
            //logoOrden.src = "data:image/png;base64,"+rese.data[0].logoblob; 
           setLogoOP("data:image/png;base64,"+rese.data[0].logoblob);
           setrzonsocialEmpresa(rese.data[0].name); 
          } catch(Exception ){console.log("error al obtener datos de empresa - getDatosEmpresa()")}
    }


    function getOrdenesPago(){
      
        let id = "getOrdenesPago";
        var date = document.getElementById("input-fecha-op").value;
        var termino = document.getElementById("input-fecha-termino").value;  
        var identificador = document.getElementById("input-identificador-op").value;  
        var rzonsocial = props.dptoid;  
        openModalLoad(); 
        fetch(process.env.REACT_APP_API_URL+'?id='+id+'&fecha='+date+'&termino='+termino+'&rzonsocial='+rzonsocial+'&rzonsocial='+rzonsocial+'&identificador='+identificador)
        .then(response => response.json())
        .then((data) => {
            closeModalLoad();
            console.log(data);

            const recentprods = data
            .sort((a, b) => b.ID - a.ID)
            setOrdenesPago(recentprods)
        });  


        //filter data
           

    }
    function getOrdenesPagoCategoria(){
        setOrdenesPagoCategoria([]);
        let id = "getOrdenesPagoCategoria";
        var date = document.getElementById("input-fecha-op-c").value;
        var termino = document.getElementById("input-fecha-termino-c").value;  
        var rzonsocial = props.dptoid;  
        openModalLoad(); 
        fetch(process.env.REACT_APP_API_URL+'?id='+id+'&fecha='+date+'&termino='+termino+'&rzonsocial='+rzonsocial)
        .then(response => response.json())
        .then((data) => {
            closeModalLoad();
            console.log(data);
            setOrdenesPagoCategoria(data);
            let empAge = []; 
            let i = 1;
            let tot = 0;
            empAge[0] = ["Categoría","Importe", { role: 'style' }];
            for (const dataObj of data) {
                tot = tot +  parseFloat(dataObj.cantidad);
                empAge[i] = [dataObj.categoria, parseFloat(dataObj.cantidad), 'color: '+props.defaultColor];
                i++;
              }
              setTotales(tot);
              //setRegistros(res.data.length);
              setChartData(empAge);  
        });  
           

    }

    function openModalPagada(ordencompraf) {
        setIsOpenPagada(true);
        setordenP(ordencompraf);
        }
        function closeModalPagada() {
            setIsOpenPagada(false);
            }

            async function guardarFechaDePago(ordencompraf){ 
                
                if(!document.getElementById("fechadepago").value == ""){
                closeModalPagada();
                let fd1 = new FormData() 
                fd1.append("id","guardarFechaDePago") 
                fd1.append("fecha",document.getElementById("fechadepago").value) 
                fd1.append("orden",ordencompraf) 
                      const res1 = await axios.post(process.env.REACT_APP_API_URL, fd1);
                      notify(res1.data);
                
                }else{
                    notify("Seleccione una fecha");
                }
            }

    async function ActualizarStatus(id, ordencompraf){
        /*  GET ROW OF WHERE SELECT WAS CHANGED  */
        var nuevoEstado = document.getElementById("sel-estado-"+id).value;
        
 
        if(window.confirm('Quieres actualizar a: ' + nuevoEstado)){
            let fd = new FormData() 
            fd.append("id", "updateOrdenPago")
            fd.append("idorden", id)
            fd.append("nvoestado", nuevoEstado)
            const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
            if (nuevoEstado === 'Pagada') {
                openModalPagada(ordencompraf);
            }
            
            notify(res.data.trim());
            getOrdenesPago();
        }
    }

    async function ActualizarTipo(id){
       
        var nuevoTipo = document.getElementById("sel-tipo-"+id).value;
 
        if(window.confirm('Quieres actualizar a: ' + nuevoTipo)){
            let fd = new FormData() 
            fd.append("id", "tipoOrdenPago")
            fd.append("idorden", id)
            fd.append("nvotipo", nuevoTipo)
            const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
            
            notify(res.data.trim());
            getOrdenesPago();
        }
    }

    async function actualizarFecha(folio) { 
        var date = document.getElementById(folio).value; 
        if(window.confirm('Actualizar fecha de la orden de pago con folio: ' + folio + ' al día: ' + date)){  
         let fd = new FormData()  
         fd.append("id", "actualizarFechaOrdenPago") 
         fd.append("idorden", folio) 
         fd.append("fechaorden", date) 
         const res = await axios.post(process.env.REACT_APP_API_URL, fd);  
          
         notify(res.data.trim()); 
         getOrdenesPago(); 
        }
    
    }

    async function actualizarCategoriaOrdenPago(folio) { 
        var categoria = document.getElementById("sel-categoria-"+folio).value; 
        if(window.confirm('Actualizar categoría de la orden de pago con folio: ' + folio + ' a la categoría: ' + categoria)){  
         let fd = new FormData()  
         fd.append("id", "actualizarCategoriaOrdenPago") 
         fd.append("folio", folio) 
         fd.append("categoria", categoria) 
         const res = await axios.post(process.env.REACT_APP_API_URL, fd);  
          
         notify(res.data.trim()); 
         getOrdenesPago(); 
        }
    
    }


    

    async function AgregarfacturaPago(cell){ 
        console.log(cell);
        document.getElementById('input-fact').click();
    
    }
    async function postFile(id){
        let fd = new FormData() 
        fd.append("id", "guardarFactura")
        fd.append("idorden", id)
        fd.append("file", document.getElementById("input-fact").files[0]) 
		const res = await axios.post(process.env.REACT_APP_API_URL,  fd, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        
        
        });  
        notify(res.data.trim());
        //getOrdenes();
        getOrdenesPago();
    }   
    var numeroALetras = (function() {
       
        // Código basado en https://gist.github.com/alfchee/e563340276f89b22042a
            function Unidades(num){
        
                switch(num)
                {
                    case 1: return 'UN';
                    case 2: return 'DOS';
                    case 3: return 'TRES';
                    case 4: return 'CUATRO';
                    case 5: return 'CINCO';
                    case 6: return 'SEIS';
                    case 7: return 'SIETE';
                    case 8: return 'OCHO';
                    case 9: return 'NUEVE';
                }
        
                return '';
            }//Unidades()
        
            function Decenas(num){
        
                let decena = Math.floor(num/10);
                let unidad = num - (decena * 10);
        
                switch(decena)
                {
                    case 1:
                        switch(unidad)
                        {
                            case 0: return 'DIEZ';
                            case 1: return 'ONCE';
                            case 2: return 'DOCE';
                            case 3: return 'TRECE';
                            case 4: return 'CATORCE';
                            case 5: return 'QUINCE';
                            default: return 'DIECI' + Unidades(unidad);
                        }
                    case 2:
                        switch(unidad)
                        {
                            case 0: return 'VEINTE';
                            default: return 'VEINTI' + Unidades(unidad);
                        }
                    case 3: return DecenasY('TREINTA', unidad);
                    case 4: return DecenasY('CUARENTA', unidad);
                    case 5: return DecenasY('CINCUENTA', unidad);
                    case 6: return DecenasY('SESENTA', unidad);
                    case 7: return DecenasY('SETENTA', unidad);
                    case 8: return DecenasY('OCHENTA', unidad);
                    case 9: return DecenasY('NOVENTA', unidad);
                    case 0: return Unidades(unidad);
                }
            }//Unidades()
        
            function DecenasY(strSin, numUnidades) {
                if (numUnidades > 0)
                    return strSin + ' Y ' + Unidades(numUnidades)
        
                return strSin;
            }//DecenasY()
        
            function Centenas(num) {
                let centenas = Math.floor(num / 100);
                let decenas = num - (centenas * 100);
        
                switch(centenas)
                {
                    case 1:
                        if (decenas > 0)
                            return 'CIENTO ' + Decenas(decenas);
                        return 'CIEN';
                    case 2: return 'DOSCIENTOS ' + Decenas(decenas);
                    case 3: return 'TRESCIENTOS ' + Decenas(decenas);
                    case 4: return 'CUATROCIENTOS ' + Decenas(decenas);
                    case 5: return 'QUINIENTOS ' + Decenas(decenas);
                    case 6: return 'SEISCIENTOS ' + Decenas(decenas);
                    case 7: return 'SETECIENTOS ' + Decenas(decenas);
                    case 8: return 'OCHOCIENTOS ' + Decenas(decenas);
                    case 9: return 'NOVECIENTOS ' + Decenas(decenas);
                }
        
                return Decenas(decenas);
            }//Centenas()
        
            function Seccion(num, divisor, strSingular, strPlural) {
                let cientos = Math.floor(num / divisor)
                let resto = num - (cientos * divisor)
        
                let letras = '';
        
                if (cientos > 0)
                    if (cientos > 1)
                        letras = Centenas(cientos) + ' ' + strPlural;
                    else
                        letras = strSingular;
        
                if (resto > 0)
                    letras += '';
        
                return letras;
            }//Seccion()
        
            function Miles(num) {
                let divisor = 1000;
                let cientos = Math.floor(num / divisor)
                let resto = num - (cientos * divisor)
        
                let strMiles = Seccion(num, divisor, 'UN MIL', 'MIL');
                let strCentenas = Centenas(resto);
        
                if(strMiles == '')
                    return strCentenas;
        
                return strMiles + ' ' + strCentenas;
            }//Miles()
        
            function Millones(num) {
                let divisor = 1000000;
                let cientos = Math.floor(num / divisor)
                let resto = num - (cientos * divisor)
        
                let strMillones = Seccion(num, divisor, 'UN MILLON', 'MILLONES');
                let strMiles = Miles(resto);
        
                if(strMillones == '')
                    return strMiles;
        
                return strMillones + ' ' + strMiles;
            }//Millones()
        
            return function NumeroALetras(num, currency) {
                currency = currency || {};
                let data = {
                    numero: num,
                    enteros: Math.floor(num),
                    centavos: (((Math.round(num * 100)) - (Math.floor(num) * 100))),
                    letrasCentavos: '',
                    letrasMonedaPlural: currency.plural || 'PESOS',//'PESOS', 'Dólares', 'Bolívares', 'etcs'
                    letrasMonedaSingular: currency.singular || 'PESO', //'PESO', 'Dólar', 'Bolivar', 'etc'
                    letrasMonedaCentavoPlural: currency.centPlural || 'CENTAVOS',
                    letrasMonedaCentavoSingular: currency.centSingular || 'CENTAVO'
                };
        
                if (data.centavos > 0) {
                    data.letrasCentavos = 'CON ' + (function () {
                            if (data.centavos == 1)
                                return Millones(data.centavos) + ' ' + data.letrasMonedaCentavoSingular;
                            else
                                return Millones(data.centavos) + ' ' + data.letrasMonedaCentavoPlural;
                        })();
                };
        
                if(data.enteros == 0)
                    return 'CERO ' + data.letrasMonedaPlural + ' ' + data.letrasCentavos;
                if (data.enteros == 1)
                    return Millones(data.enteros) + ' ' + data.letrasMonedaSingular + ' ' + data.letrasCentavos;
                else
                    return Millones(data.enteros) + ' ' + data.letrasMonedaPlural + ' ' + data.letrasCentavos;
            };
        
        })();
        
        // Modo de uso: 500,34 USD
        numeroALetras(500.34, {
          plural: 'dólares estadounidenses',
          singular: 'dólar estadounidense',
          centPlural: 'centavos',
          centSingular: 'centavo'
        });

      

        async function guardarOrden(tipo){ 
            var Tarjeta = "";
            var Banco = "";
            var Cantidad = "";
            var Receptor = "";
            var Concepto = "";
            var Solicita = "";
            var Revisa = "";
            var Revisa1 = "";
            var Autoriza = "";
             if (tipo=="1"){ 
                  Banco = document.getElementById("nop-bancoretiro").value ;
                  Cantidad = document.getElementById("nop-cantidad").value;
                  Receptor = document.getElementById("nop-anombre").value;
                  Concepto = document.getElementById("nop-concepto").value;
                  Solicita = document.getElementById("nfirmas-solicito").value;
                  Revisa = document.getElementById("nfirmas-reviso").value;
                  Revisa1 = document.getElementById("nfirmas-reviso1").value;
                  Autoriza = document.getElementById("nfirmas-autorizo").value;
                  Tarjeta = ""; 
                
             } else{

                  Tarjeta = document.getElementById("op-tarjeta").value;
                  Banco = document.getElementById("nop-bancoretiroN").value;
                  Cantidad = document.getElementById("nop-cantidadN").value;
                  Receptor = document.getElementById("nop-anombreN").value;
                  Concepto = document.getElementById("nop-conceptoN").value;
                  Solicita = document.getElementById("nfirmas-solicitoN").value;
                  Revisa = document.getElementById("nfirmas-revisoN").value;
                  Revisa1 = document.getElementById("nfirmas-revisoN1").value;
                  Autoriza = document.getElementById("nfirmas-autorizoN").value;
             }  
           //  alert(document.getElementById("op-tarjeta").options[document.getElementById("op-tarjeta").selectedIndex].value);
             let topost = new FormData();
             topost.append("id", "guardarOrdenPago"); //actualizar orden de pago 
             topost.append("banco", Banco);
             topost.append("cantidad", Cantidad);
             topost.append("receptor", Receptor);
             topost.append("concepto", Concepto);
             topost.append("solicita", Solicita);
             topost.append("tarjeta", Tarjeta);
             topost.append("tipo", tipo);
             topost.append("revisa", Revisa);
             topost.append("revisa1", Revisa1);
             topost.append("autoriza", Autoriza);
             topost.append("IDempresa", props.dptoid);
             
             openModalLoad();
         fetch(process.env.REACT_APP_API_URL,{
             method: 'POST',
             mode: 'cors',
             body: topost
         })
         .then(response => response.text())
         .then((data) => {
            closeModalLoad();
             notify(data.trim());
             getOrdenesPago();
             setIsOpenGenerar(false);
         });



             
        }
        function mostrarOrden(){
            var select = document.getElementById("slc-tipo-orden").value;
            
            document.getElementById("nvaorden1").setAttribute('hidden','true');
            document.getElementById("nvaorden2").setAttribute('hidden','true');
            if(select == 1){
              
                document.getElementById("nvaorden1").removeAttribute('hidden'); 
            }else if(select == "2"){
                document.getElementById("nvaorden2").removeAttribute('hidden');
            }
            
            
                
        }
        
        function getDecimal(n){
            
            var str = n.toString();
            var decimalOnly = 0;

            if( str.indexOf('.') != -1 ){ //check if has decimal
                var decimalOnly = (Math.abs(n).toString().split('.')[1]);
            }
        
            if(String(decimalOnly).length == 1){
                decimalOnly = decimalOnly + "0";
            }  
           // decimalOnly = pad(decimalOnly,2);
            return decimalOnly;
        }

        function pad(n, len) {
            return (new Array(len + 1).join('0') + n).slice(-len);
          }


        
    
   async function verOrdenPago(ID, ordencompraf){
    getUsuarios();
        let tipo;  
        var section = document.getElementById("ordenpagodiv");
        setGenerada(0); 
		setSurtida(0); 
		setEstadoCancelada(0);
        openModalLoad(); 
        document.getElementById("orden2").setAttribute('hidden','true');
        document.getElementById("orden1").setAttribute('hidden','true');
        let id = "getDetallesOrdenPago"; 
        const rese = await axios.get(process.env.REACT_APP_API_URL+'?id='+id+'&idop='+ID+'&rzonsocial='+props.dptoid);  
       console.log(rese.data);
       /* fetch(process.env.REACT_APP_API_URL+'?id='+id)
        .then(response => response.json())
        .then(data => setOrdenesPago(data));*/
        var orden;
       
        closeModalLoad();
        
        for (let item of rese.data) {
            if(item.ID == ID){
                orden = item;
                tipo= item.tipo
            }
        }
 try{
		if(orden.estado == "Generada"){
			setGenerada(50);
		}
		 if(orden.estado == "Surtida"){
			setGenerada(50); 
			setSurtida(50);
		}
        if(orden.estado == "Cancelada"){
			setGenerada(0); 
			setSurtida(0);
			setEstadoCancelada(100);
		}
    }catch (Exception){}
        
        console.log(orden);
        document.getElementById("tipoN").value=tipo;
        if(tipo=='1'){
            
            document.getElementById("orden1").removeAttribute('hidden');
            document.getElementById("header-rzonsocial").innerHTML = orden.rzonsocial;
            document.getElementById("header-categoria").innerHTML = orden.categoria;
            //document.getElementById("op-numero").value = orden.ID + " (" + orden.IDserie+")";
            document.getElementById("op-numero").value =orden.IDserie;
            document.getElementById("op-numero").name =orden.ID;
            document.getElementById("op-fecha").value = formatDate(orden.Fecha);
            document.getElementById("op-bancoretiro").value = orden.Banco;
           
            document.getElementById("op-cantidad").value = formatNumber(orden.Cantidad);
            document.getElementById("op-cliente").value = orden.extra;
            document.getElementById("op-pedido").value = orden.extra1;
        
            if(orden.folioempresa != null){
            document.getElementById("op-folio-orden-compra").value = (orden.folioempresa);
            document.getElementById("op-folio-orden-requisicion").value = (orden.idrequisicion);
           // document.getElementById("op-folio-orden-compra").setAttribute("onclick","props.verOrden('blah');");
            }else{
            document.getElementById("op-folio-orden-compra").value = "-";
            document.getElementById("op-folio-orden-requisicion").value = "-";
           // document.getElementById("op-folio-orden-compra").setAttribute("disabled","");
            }
            if(orden.Receptor != null && orden.Receptor  != ""){
                document.getElementById("op-anombre").value = orden.Receptor;
            }else if(orden.proveedor != undefined){
                document.getElementById("op-anombre").value = orden.proveedor;
            }else{
                document.getElementById("op-anombre").value = "";
            }
            if(orden.Concepto != null){
                document.getElementById("op-concepto").value = orden.Concepto;
            }else if(orden.identificador != undefined){
                document.getElementById("op-concepto").value = orden.identificador;
            }else{
                document.getElementById("op-concepto").value = "";
            }
            document.getElementById("firmas-solicito").value = orden.Solicita;
            document.getElementById("firmas-reviso").value = orden.Revisa;
            document.getElementById("firmas-reviso1").value = orden.Revisa1;
            document.getElementById("firmas-autorizo").value = orden.Autoriza;
            //en ausencia 
        try{
            document.getElementById("firmas-solicito-au").value = orden.Solicitaau;
            document.getElementById("firmas-reviso-au").value = orden.Revisaau;
            document.getElementById("firmas-reviso1-au").value = orden.Revisa1au;
            document.getElementById("firmas-autorizo-au").value = orden.Autorizaau;
        }catch(Exception){}

            document.getElementById("op-cantidadletra").value = numeroALetras(parseInt(orden.Cantidad)) + getDecimal(orden.Cantidad) + "/100 M.N.";
        }else{ 
            document.getElementById("op-importeletraN").value = numeroALetras(parseInt(orden.Cantidad)) + getDecimal(orden.Cantidad) + "/ 100 M.N.";
            document.getElementById("header-rzonsocial1").innerHTML = orden.rzonsocial;
            document.getElementById("header-categoria1").innerHTML = orden.categoria;
            document.getElementById("orden2").removeAttribute('hidden');
            //document.getElementById("op-numeroN").value = orden.ID; 
            document.getElementById("op-numeroN").value =orden.IDserie;
            document.getElementById("op-numeroN").name =orden.ID;
            document.getElementById("op-fechaN").value = formatDate(orden.Fecha);
            document.getElementById("op-bancoretiroN").value = orden.Banco;
            
            document.getElementById("op-cantidadN").value =   formatNumber(orden.Cantidad);
            document.getElementById("op-clienteN").value = orden.extra;
            document.getElementById("op-pedidoN").value = orden.extra1;
            if(orden.folioempresa != null){
                document.getElementById("op-folio-orden-compraN").value = (orden.folioempresa);
                document.getElementById("op-folio-orden-requisicionN").value = (orden.idrequisicion);
              //  document.getElementById("op-folio-orden-compraN").setAttribute("onclick","alert('blah');");
            }else{
                document.getElementById("op-folio-orden-compraN").value = "-";
                document.getElementById("op-folio-orden-requisicionN").value = "-";
              //  document.getElementById("op-folio-orden-compraN").setAttribute("disabled","");
            }
            document.getElementById("op-tarjeta").value = orden.Tarjeta;
            if(orden.Receptor != null && orden.Receptor != "" ){ 
                document.getElementById("op-anombreN").value = orden.Receptor;
            }else if(orden.proveedor != undefined){
                document.getElementById("op-anombreN").value = orden.proveedor;
            }else{
                document.getElementById("op-anombreN").value = "";
            }
            if(orden.Concepto != null){
                document.getElementById("op-conceptoN").value = orden.Concepto;
            }else if(orden.identificador != undefined){
                document.getElementById("op-conceptoN").value = orden.identificador;
            } else{
                document.getElementById("op-conceptoN").value = "";
            }
            
            
            document.getElementById("firmas-solicitoN").value = orden.Solicita;
            document.getElementById("firmas-revisoN").value = orden.Revisa;
            document.getElementById("firmas-revisoN1").value = orden.Revisa1;
            document.getElementById("firmas-autorizoN").value = orden.Autoriza;

            //en ausencia
            try{

            
            document.getElementById("firmas-solicitoN-au").value = orden.Solicitaau;
            document.getElementById("firmas-revisoN-au").value = orden.Revisaau;
            document.getElementById("firmas-revisoN1-au").value = orden.Revisa1au;
            document.getElementById("firmas-autorizoN-au").value = orden.Autorizaau;
            }catch(Exception){}
            
        } 
        if(orden.solicitudpago == "1"){
            document.getElementById("lblcompra").innerHTML = "SOLICITUD PAGO &nbsp; #";
        }else{
            document.getElementById("lblcompra").innerHTML = "COMPRA &nbsp; #";
        }
        
        getAutorizacion(ID, tipo);
       
        section.scrollIntoView({ behavior: "smooth", block: "start" });
           
    }

    async function getAutorizacion(ID, tipo){ 
       
        let id = "getAutorizacionOrdenPago";
        const res = await axios.get(process.env.REACT_APP_API_URL+'?id='+id+'&idop='+ID);  
        
        if(tipo == "1"){
            try{
                var firmasolicita = document.getElementById("firmasolicita");
                firmasolicita.src = process.env.REACT_APP_API_URL_DEFAULT+'b.jpg';
                delete firmasolicita.src;
                var firmarevisa = document.getElementById("firmarevisa");
                firmarevisa.src = process.env.REACT_APP_API_URL_DEFAULT+'b.jpg';
                delete firmarevisa.src;
                var firmarealiza = document.getElementById("firmarevisa1");
                firmarealiza.src = process.env.REACT_APP_API_URL_DEFAULT+'b.jpg';
                delete firmarealiza.src;
                var firmaautoriza = document.getElementById("firmaautoriza");
                firmaautoriza.src = process.env.REACT_APP_API_URL_DEFAULT+'b.jpg';
                delete firmaautoriza.src;
                } catch (Exception){}
                var unob = document.getElementById("bsolicita"); 
                var dosb = document.getElementById("brevisa");  
                var tresb = document.getElementById("brevisa1"); 
                var cuatrob = document.getElementById("bautoriza");
                //en ausencia
                var unobau = document.getElementById("bsolicitaau"); 
                var dosbau = document.getElementById("brevisaau");  
                var tresbau = document.getElementById("brevisa1au"); 
                var cuatrobau = document.getElementById("bautorizaau");

                //label solicitó 
                var lblsolicito = document.getElementById("lblsolicito");
                var lblreviso = document.getElementById("lblreviso");
                var lblreviso1 = document.getElementById("lblreviso1");
                var lblautorizo = document.getElementById("lblautorizo");
                lblsolicito.innerHTML = "SOLICITÓ";
                lblreviso.innerHTML = "REVISÓ";
                lblreviso1.innerHTML = "REVISÓ";
                lblautorizo.innerHTML = "AUTORIZÓ";
        }
        if(tipo == "2"){
            try{
                var firmasolicita = document.getElementById("firmasolicita2");
                firmasolicita.src = process.env.REACT_APP_API_URL_DEFAULT+'b.jpg';
                delete firmasolicita.src;
                var firmarevisa = document.getElementById("firmarevisa2");
                firmarevisa.src = process.env.REACT_APP_API_URL_DEFAULT+'b.jpg';
                delete firmarevisa.src;
                var firmarealiza = document.getElementById("firmarevisa12");
                firmarealiza.src = process.env.REACT_APP_API_URL_DEFAULT+'b.jpg';
                delete firmarealiza.src;
                var firmaautoriza = document.getElementById("firmaautoriza2");
                firmaautoriza.src = process.env.REACT_APP_API_URL_DEFAULT+'b.jpg';
                delete firmaautoriza.src;
                } catch (Exception){}
                var unob = document.getElementById("bsolicita2"); 
                var dosb = document.getElementById("brevisa2");  
                var tresb = document.getElementById("brevisa12"); 
                var cuatrob = document.getElementById("bautoriza2");
                //en ausencia
                var unobau = document.getElementById("bsolicita2au"); 
                var dosbau = document.getElementById("brevisa2au");  
                var tresbau = document.getElementById("brevisa12au"); 
                var cuatrobau = document.getElementById("bautoriza2au");

                
                //label solicitó 
                var lblsolicito = document.getElementById("lblsolicitoN");
                var lblreviso = document.getElementById("lblrevisoN");
                var lblreviso1 = document.getElementById("lblrevisoN1");
                var lblautorizo = document.getElementById("lblautorizoN");
                lblsolicito.innerHTML = "SOLICITÓ";
                lblreviso.innerHTML = "REVISÓ";
                lblreviso1.innerHTML = "REVISÓ";
                lblautorizo.innerHTML = "AUTORIZÓ";
        }
       
         

                unob.setAttribute("hidden", true);
                dosb.setAttribute("hidden", true);
                tresb.setAttribute("hidden", true);
                cuatrob.setAttribute("hidden", true);

                var solicitadof = 0; 
                var revisadof = 0; 
                var realizadof = 0; 
                var autorizadof = 0; 

                var CGA = 0; 

                let solicitai = "";
                let revisai = "";
                let revisai1 = "";
                let autorizai = "";
                for(let a = 0; a < 2; a++){  
                    for(let i=0;i<res.data.length;i++){ 
                        
                        if(res.data[i].tipo == "100" || res.data[i].tipo == "1000"){
                            if(res.data[i].autorizado == "1"){ 
                                CGA = CGA+1;
                            } 
                            //SOLICITA I
                            if(res.data[i].tipo == "100"){
                                solicitai = res.data[i].name ;
                            }
                            if((props.userid == res.data[i].userid) && (res.data[i].autorizado == "0") && (solicitadof == 0)){
                                delete firmasolicita.src; 
                                if(res.data[i].tipo == "100"){
                                    unob.removeAttribute("hidden");
                                    unob.setAttribute("name", "1");
                                }
                                if(res.data[i].tipo == "1000"){
                                    unobau.removeAttribute("hidden");
                                    unobau.setAttribute("name", "1");
                                }
                                 
                            } else { 
                                // mensaje 
                                if(res.data[i].autorizado == "1"  ){
                                      solicitadof = 1;
                                    if(res.data[i].tipo == "100"){
                                        firmasolicita.src = "data:image/png;base64,"+res.data[i].imagen;  
                                        unob.setAttribute("hidden", true);
                                        try{unobau.setAttribute("hidden", true);}catch(Exception){}

                                    }
                                    if(res.data[i].tipo == "1000"){
                                        firmasolicita.src = "data:image/png;base64,"+res.data[i].imagen;  
                                        unob.setAttribute("hidden", true);
                                        unobau.setAttribute("hidden", true);
                                        lblsolicito.innerHTML = res.data[i].name + " EN AUSENCIA DE " + solicitai; 

                                    }
                                }  
                            }
                        } 
                        
                        if(res.data[i].tipo == "200" || res.data[i].tipo == "2000"){ 
                            if(res.data[i].autorizado == "1"){ 
                                CGA = CGA+1;
                            } 
                            //REVISA I
                            if(res.data[i].tipo == "200"){
                                revisai = res.data[i].name ;
                            }
                            if((props.userid == res.data[i].userid) && (res.data[i].autorizado == "0") && (revisadof == 0)){    
                                delete firmarevisa.src;
                                if(res.data[i].tipo == "200"){
                                    dosb.removeAttribute("hidden");
                                    dosb.setAttribute("name", "2");
                                }
                                if(res.data[i].tipo == "2000" ){
                                    dosbau.removeAttribute("hidden");
                                    dosbau.setAttribute("name", "2");
                                }
                                 
                            } else {
                                if(res.data[i].autorizado == "1"){
                                    
                                    revisadof = 1;
                                    if(res.data[i].tipo == "200"){
                                        firmarevisa.src = "data:image/png;base64,"+res.data[i].imagen; 
                                        dosb.setAttribute("hidden", true);
                                        dosbau.setAttribute("hidden", true);
                                    }
                                    if(res.data[i].tipo == "2000"){
                                       
                                        firmarevisa.src = "data:image/png;base64,"+res.data[i].imagen; 
                                        dosb.setAttribute("hidden", true);
                                        dosbau.setAttribute("hidden", true);
                                        lblreviso.innerHTML = res.data[i].name + " EN AUSENCIA DE " + revisai;
                                    }
                                    
                                } 
                            } 
                        }
                        
                        if(res.data[i].tipo == "300" || res.data[i].tipo == "3000"){ 
                            if(res.data[i].autorizado == "1"){ 
                                CGA = CGA+1;
                            } 
                            //REVISA1 I
                            if(res.data[i].tipo == "300"){
                                revisai1 = res.data[i].name ;
                            }
                            if((props.userid == res.data[i].userid) && (res.data[i].autorizado == "0") && (realizadof == 0)){
                                delete firmarealiza.src;
                                if(res.data[i].tipo == "300"){
                                    tresb.removeAttribute("hidden");
                                    tresb.setAttribute("name", "3");
                                }
                                if(res.data[i].tipo == "3000"){
                                    tresbau.removeAttribute("hidden");
                                    tresbau.setAttribute("name", "3");
                                }
                                
                            } else {
                                if(res.data[i].autorizado == "1"){
                                    realizadof = 1;
                                    if(res.data[i].tipo == "300"){
                                        firmarealiza.src = "data:image/png;base64,"+res.data[i].imagen; 
                                        tresb.setAttribute("hidden", true);
                                        tresbau.setAttribute("hidden", true);
                                    }
                                    if(res.data[i].tipo == "3000"){
                                        firmarealiza.src = "data:image/png;base64,"+res.data[i].imagen; 
                                        tresb.setAttribute("hidden", true);
                                        tresbau.setAttribute("hidden", true);
                                        lblreviso1.innerHTML = res.data[i].name + " EN AUSENCIA DE " + revisai1;
                                    }
                                    
                                }  
                            }
                        }
                        
                        if(res.data[i].tipo == "400" || res.data[i].tipo == "4000"){ 
                            //AUTORIZA I
                            if(res.data[i].tipo == "400"){
                                autorizai = res.data[i].name ;
                            }
                            if((props.userid == res.data[i].userid) && (res.data[i].autorizado == "0") && (autorizadof == 0)){
                                if((solicitadof == 1) && (revisadof == 1) && (realizadof == 1)){         
                                        delete firmaautoriza.src;
                                        if(res.data[i].tipo == "400"){
                                            cuatrob.removeAttribute("hidden");
                                            cuatrob.setAttribute("name", "4");
                                        }
                                        if(res.data[i].tipo == "4000"){
                                            cuatrobau.removeAttribute("hidden");
                                            cuatrobau.setAttribute("name", "4");
                                        }
                                        
                                    }
                            } else { 
                                if(res.data[i].autorizado == "1"){
                                    autorizadof = 1; 
                                    if(res.data[i].tipo == "400"){
                                        firmaautoriza.src = "data:image/png;base64,"+res.data[i].imagen; 
                                        cuatrob.setAttribute("hidden", true);
                                        cuatrobau.setAttribute("hidden", true);
                                    }
                                    if(res.data[i].tipo == "4000"){
                                        firmaautoriza.src = "data:image/png;base64,"+res.data[i].imagen; 
                                        cuatrob.setAttribute("hidden", true);
                                        cuatrobau.setAttribute("hidden", true);
                                        lblautorizo.innerHTML = res.data[i].name + " EN AUSENCIA DE " + autorizai;
                                    }
                                   
                                }
                            }
                                
                        }
                    }
                }




    }

  
    
	function desactivarOrdenPago(ID){
		if(window.confirm('Eliminar orden de pago: ' + ID)){
		let toSend = new FormData();
		toSend.append("id", "eliminarOrdenPago");/////////ALIVIANESE PADRINOLI SIUU
		toSend.append("folio", ID);

		fetch(process.env.REACT_APP_API_URL, {
			method: "POST",
			mode: "cors",
			body: toSend
		})
		.then(response => response.text())
		.catch(error => alert(error))
		.then((data)=> { 
			if(data){
				notify(data);
				getOrdenesPago();
				cleanForm();
			}
		})
	}
	}
 

    async function Autorizar(tipo, tipo2){
        var unob = document.getElementById("bsolicita"); 
        var dosb = document.getElementById("brevisa"); 
        var tresb = document.getElementById("brealiza");
        
        if(tipo == "400"){
           
              var idop;
              if(tipo2 == "1"){
                   idop = document.getElementById("op-numero").name;
              }else if(tipo2 == "2"){
                   idop = document.getElementById("op-numeroN").name;
              } 
                openModalLoad();
                let fd = new FormData() 
                fd.append("id", "autorizar1")
                fd.append("idordenpago", idop)
                fd.append("userid", props.userid)
                fd.append("tipo", tipo) 
                const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
                closeModalLoad();
                getOrdenesPago();
                
                notify(res.data.trim());
                verOrdenPago(idop);
               // let foliof = document.getElementById("folio").value;
             //   verOrdenPago(document.getElementById("idrequisicion").value,foliof);
            /*  
            } else {
                notify("No es posible autorizar");
            }*/
        } else {
            openModalLoad();
            var idop;
            if(tipo2 == "1"){
                 idop = document.getElementById("op-numero").name;
            }else if(tipo2 == "2"){
                 idop = document.getElementById("op-numeroN").name;
            }
              
            let fd = new FormData() 
            fd.append("id", "autorizar1")
            fd.append("idordenpago", idop)
            fd.append("userid", props.userid)
            fd.append("tipo", tipo) 
            const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
            closeModalLoad();
            getOrdenesPago();
            
            notify(res.data.trim());
            verOrdenPago(idop);
          //  let foliof = document.getElementById("folio").value;
           // verOrdenPago(document.getElementById("idrequisicion").value,foliof); 
            
        }
       
    }

    function actualizarOrdenPago(){
            
           

            var tipo = document.getElementById("tipoN").value;

            if(tipo=='1'){
                var folio = document.getElementById("op-numero").name;
            if(folio != ""){

                var Banco = document.getElementById("op-bancoretiro").value ;
                
                var Cantidad = document.getElementById("op-cantidad").value ;
                Cantidad = Cantidad.replace(/,/g, '');
                Cantidad = Cantidad.replace('$', '');

                var Receptor = document.getElementById("op-anombre").value ;
                var Concepto = document.getElementById("op-concepto").value ;
                var Solicita = document.getElementById("firmas-solicito").value ;
                var Revisa = document.getElementById("firmas-reviso").value ;
                var Revisa1 = document.getElementById("firmas-reviso1").value ;
                var Autoriza = document.getElementById("firmas-autorizo").value ;
                var Extra = document.getElementById("op-cliente").value ;
                var Extra1 = document.getElementById("op-pedido").value ;
                try{

                
                var Solicitaau = document.getElementById("firmas-solicito-au").value ;
                var Revisaau = document.getElementById("firmas-reviso-au").value ;
                var Revisa1au = document.getElementById("firmas-reviso1-au").value ;
                var Autorizaau = document.getElementById("firmas-autorizo-au").value ;
            }catch(Exception){}
                let topost = new FormData();
                    topost.append("id", "actualizarOrdenPago"); //actualizar orden de pago
                    topost.append("folio", folio);
                    topost.append("banco", Banco);
                    topost.append("cantidad", Cantidad);
                    topost.append("receptor", Receptor);
                    topost.append("concepto", Concepto);
                    topost.append("solicita", Solicita);
                    topost.append("revisa", Revisa);
                    topost.append("revisa1", Revisa1);
                    topost.append("autoriza", Autoriza);
                    topost.append("extra", Extra);
                    topost.append("extra1", Extra1);
                    //en ausencia
                    topost.append("solicitaau", Solicitaau);
                    topost.append("revisaau", Revisaau);
                    topost.append("revisa1au", Revisa1au);
                    topost.append("autorizaau", Autorizaau);

                fetch(process.env.REACT_APP_API_URL,{
                    method: 'POST',
                    mode: 'cors',
                    body: topost
                })
                .then(response => response.text())
                .then((data) => {
                    notify(data.trim());
                    verOrdenPago(folio);
                });

                getOrdenesPago();
        }else{
            notify("Seleccione una orden de pago");
        }
            }else{
                var folio1 = document.getElementById("op-numeroN").name;
            if(folio1 != ""){
                var Banco1 = document.getElementById("op-bancoretiroN").value ;
                var Cantidad1 = document.getElementById("op-cantidadN").value ;
                Cantidad1 = Cantidad1.replace(/,/g, '');
                Cantidad1 = Cantidad1.replace('$', '');
                var Receptor1 = document.getElementById("op-anombreN").value ;
                var Concepto1 = document.getElementById("op-conceptoN").value ;
                var Solicita1 = document.getElementById("firmas-solicitoN").value ;
                var Revisa1 = document.getElementById("firmas-revisoN").value ;
                var Revisa11 = document.getElementById("firmas-revisoN1").value ;
                var Autoriza1 = document.getElementById("firmas-autorizoN").value ;
               
                var Tarjeta = document.getElementById("op-tarjeta").value ;
                var Extra = document.getElementById("op-clienteN").value ;
                var Extra1 = document.getElementById("op-pedidoN").value ;
try{
                var Solicitaau1 = document.getElementById("firmas-solicitoN-au").value ;
                var Revisaau1 = document.getElementById("firmas-revisoN-au").value ;
                var Revisa1au1 = document.getElementById("firmas-revisoN1-au").value ;
                var Autorizaau1 = document.getElementById("firmas-autorizoN-au").value ;
            }catch(Exception){}

                let topost1 = new FormData();
                    topost1.append("id", "actualizarOrdenPago"); //actualizar orden de pago
                    topost1.append("folio", folio1);
                    topost1.append("banco", Banco1);
                    topost1.append("cantidad", Cantidad1);
                    topost1.append("receptor", Receptor1);
                    topost1.append("concepto", Concepto1);
                    topost1.append("solicita", Solicita1);
                    topost1.append("autoriza", Autoriza1);
                    topost1.append("revisa", Revisa1);
                    topost1.append("revisa1", Revisa11);
                    topost1.append("tarjeta", Tarjeta);
                    topost1.append("extra", Extra);
                    topost1.append("extra1", Extra1);
                    //en ausencia
                    topost1.append("solicitaau", Solicitaau1);
                    topost1.append("revisaau", Revisaau1);
                    topost1.append("revisa1au", Revisa1au1);
                    topost1.append("autorizaau", Autorizaau1);


                fetch(process.env.REACT_APP_API_URL,{
                    method: 'POST',
                    mode: 'cors',
                    body: topost1
                })
                .then(response => response.text())
                .then((data) => {
                    notify(data.trim());
                    verOrdenPago(folio1);
                });

                getOrdenesPago();
        }else{
            notify("Seleccione una orden de pago");
        }
            }


            
    }

    

    function cleanForm(){
        let tipo = document.getElementById("tipoN").value
        if(tipo=='1'){
            document.getElementById("op-bancoretiro").value = null;
            document.getElementById("op-cantidadletra").value = null;
            document.getElementById("op-anombre").value = null;
            document.getElementById("op-concepto").value = null;
            document.getElementById("firmas-solicito").value = null;
            document.getElementById("firmas-autorizo").value = null;
        }else{
            document.getElementById("op-bancoretiroN").value = null;
            document.getElementById("op-anombreN").value = null;
            document.getElementById("op-tarjeta").value = null;
            document.getElementById("op-conceptoN").value = null;
            document.getElementById("firmas-solicitoN").value = null;
            document.getElementById("firmas-autorizoN").value = null;
            document.getElementById("firmas-revisoN").value = null;
        }
        
    }

    function verOrden1(form){
        let foliooc;
        let folioreq;
        let folioordenpago;
        if(form == "1"){
             foliooc = document.getElementById("op-folio-orden-compra").value;
             folioreq = document.getElementById("op-folio-orden-requisicion").value;
             folioordenpago = document.getElementById("op-numero").name;
        }
        if(form == "2"){
            foliooc = document.getElementById("op-folio-orden-compraN").value;
            folioreq = document.getElementById("op-folio-orden-requisicionN").value;
            folioordenpago = document.getElementById("op-numeroN").name;
        }
        if(foliooc != "-" && folioreq != "-"){
            props.verOrden(folioreq, foliooc, folioordenpago);
            console.log(foliooc + " " + folioreq);
        }
        
        //set selected requisiciones send idrequisicion
       /* setCargarRequisicion1(idrequisicion); 
        setCargarOrden(idorden); 
        if(window.confirm('¿Ver orden de compra: ' + idorden +'?')){
            setSelect('4');
        }*/
        
    }


    async function addDocumento(){
		openModalLoad();
		var documento = document.getElementById("documento"); 
		let fd = new FormData() 
		fd.append("id","cargarDocumento")
		fd.append("folio", folioActividad1) 
		fd.append("documento", documento.files[0]); 
		setListaDocumentos([]);
		const res = await axios.post(process.env.REACT_APP_API_URL, fd);
		closeModalLoad();
		closeModalA();
		notify(res.data.trim());
		
	}



    function Pdf(){

        var unob = document.getElementById("bsolicita"); 
        var dosb = document.getElementById("brevisa");  
        var tresb = document.getElementById("brevisa1"); 
        var cuatrob = document.getElementById("bautoriza");
        try{
        var divausencia = document.getElementById("div-ausencia");
        var divausencia1 = document.getElementById("div-ausencia1");
    }catch(Exception){}

                unob.setAttribute("hidden", true);
                dosb.setAttribute("hidden", true);
                tresb.setAttribute("hidden", true);
                cuatrob.setAttribute("hidden", true);
                try{
                divausencia.setAttribute("hidden", true);
                divausencia1.setAttribute("hidden", true);
            }catch(Exception){}
 
        let tipo = document.getElementById("tipoN").value;
        
            //ocultar los selectores seleccione
            if(document.getElementById("firmas-solicitoN").value == "Seleccione"){
                document.getElementById("footer-solicito-1").setAttribute("hidden", true);
            } 
            if(document.getElementById("firmas-revisoN").value == "Seleccione"){ 
                document.getElementById("footer-reviso-1").setAttribute("hidden", true);  
            }
            if(document.getElementById("firmas-revisoN1").value == "Seleccione"){
                document.getElementById("footer-reviso1-1").setAttribute("hidden", true);
            }
            if(document.getElementById("firmas-autorizoN").value == "Seleccione"){
                document.getElementById("footer-autorizo-1").setAttribute("hidden", true);
            }

            if(document.getElementById("firmas-solicito").value == "Seleccione"){
                document.getElementById("footer-solicito").setAttribute("hidden", true);
            } 
            if(document.getElementById("firmas-reviso").value == "Seleccione"){ 
                document.getElementById("footer-reviso").setAttribute("hidden", true);  
            }
            if(document.getElementById("firmas-reviso1").value == "Seleccione"){
                document.getElementById("footer-reviso1").setAttribute("hidden", true);
            }
            if(document.getElementById("firmas-autorizo").value == "Seleccione"){
                document.getElementById("footer-autorizo").setAttribute("hidden", true);
            }
  


        if(tipo=='1'){
            try{
                var folio = document.getElementById("op-numero").name; 
                if(folio != ""){
               
                var fecha = document.getElementById("op-fecha").value;
                const input = document.getElementById('div-ordenpago');
                html2canvas(input).then((canvas) => {
                    let imgWidth = 190;
                    let imgHeight = canvas.height * imgWidth / canvas.width;
                    const imgData = canvas.toDataURL('img/png');
                    const pdf = new jsPDF('p', 'mm', 'a4');
                    pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
                    
                    // pdf.output('dataurlnewwindow');
                    pdf.save("Orden de Pago F"+ folio +" "+ fecha +".pdf");
                    try{
                    divausencia.removeAttribute("hidden");
                    divausencia1.removeAttribute("hidden");
                }catch(Exception){}
                })
                ;
            }else{
                notify("Seleccione una orden de pago");
            }
             
    
            } catch(Exception){}
        }else{
            try{
                var folio1 = document.getElementById("op-numeroN").name; 
                if(folio1 != ""){
               
                var fecha1 = document.getElementById("op-fechaN").value;
                const input = document.getElementById('div-ordenpago1');
                html2canvas(input).then((canvas) => {
                    let imgWidth = 190;
                    let imgHeight = canvas.height * imgWidth / canvas.width;
                    const imgData = canvas.toDataURL('img/png');
                    const pdf = new jsPDF('p', 'mm', 'a4');
                    pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
                    
                    // pdf.output('dataurlnewwindow');
                    pdf.save("Orden de Pago F"+ folio1 +" "+ fecha1 +".pdf");
                })
                ;


                    document.getElementById("footer-solicito-1").removeAttribute("hidden");
                    document.getElementById("footer-reviso-1").removeAttribute("hidden");
                    document.getElementById("footer-reviso1-1").removeAttribute("hidden");
                    document.getElementById("footer-autorizo-1").removeAttribute("hidden");

                    document.getElementById("footer-solicito").removeAttribute("hidden");
                    document.getElementById("footer-reviso").removeAttribute("hidden");
                    document.getElementById("footer-reviso1").removeAttribute("hidden");
                    document.getElementById("footer-autorizo").removeAttribute("hidden");


            }else{
                notify("Seleccione una orden de pago");
            }
             
    
            } catch(Exception){}

        }
        
    }

    const style = `
        #btns{
            display: flex;
            flex-direction: column;
            padding-bottom: 2vw;
            gap: 2vw;
        }
        .p-3{
            display: flex;
            flex-direction: column;
            gap: 2vw;
        }
        .btn-clean{
            font-weight: bold;
            border-width: 2px;
        }
    `;

    function XML(tipoOP){
        //notify("factura cargada");
        let loadedFile;
        if(tipoOP == "1"){
             loadedFile = document.getElementById("cargaXML").files[0];
        }else if(tipoOP == "2"){
             loadedFile = document.getElementById("cargaXML1").files[0];
        }
        
        //var XMLParser = require('react-xml-parser') 
        //var xml = new XMLParser().parseFromString(loadedFile) 
        let total1 = 0;
        var reader = new FileReader();
        reader.readAsText(loadedFile);
        reader.onloadend = function(){
            var xmlData = reader.result;
           
            var XMLParser = require('react-xml-parser') 
            var xml = new XMLParser().parseFromString(xmlData) 

            //Total 
            console.log(xml.attributes.Total);
            //Proveedor 
            console.log(xml.children[0].attributes.Nombre);
            //Descripcion 
            console.log(xml.children[2].children[0].attributes.Descripcion);
            if(document.getElementById("nop-cantidad").value != ""){
                total1 = parseFloat(document.getElementById("nop-cantidad").value);
            }
           if(tipoOP == "1"){
            document.getElementById("nop-cantidad").value = total1 + parseFloat(xml.attributes.Total); 
            document.getElementById("nop-anombre").value = xml.children[0].attributes.Nombre; 
            document.getElementById("nop-concepto").value = xml.children[2].children[0].attributes.Descripcion; 
           }else if(tipoOP == "2"){  
            document.getElementById("nop-cantidadN").value = total1 + parseFloat(xml.attributes.Total); 
            document.getElementById("nop-anombreN").value = xml.children[0].attributes.Nombre; 
            document.getElementById("nop-conceptoN").value = xml.children[2].children[0].attributes.Descripcion; 
           }
            
        };
        if(tipoOP == "1"){
            document.getElementById("cargaXML").value = '';
        }else if(tipoOP == "2"){
            document.getElementById("cargaXML1").value = '';
        }
        
    }
    
 

/* 
Columnas tabla
*/
const columns = [
    {
        name: 'Folio', 
        sortable: true, 
        width: "100px",
        selector: row => row.ID,
        
    },
    {
        name: 'Serie', 
        sortable: true,     
        width: "80px",
        selector: row => row.IDserie,
    },
    {
        name: 'Fecha', 
        width: "120px", 
        cell: (row) => {
            return (
                <input style={{width: '110px'}} type="date" id={row.ID} onChange={() => actualizarFecha(row.ID)} value={(row.Fecha).substring(0,10)}></input>
            )
        }
    },
    {
        name: 'Orden',
        sortable: true, 
        selector: row => row.ordencompraf,
    },
    {
        name: 'Identificador', 
        width: "280px",
        sortable: true, 
        cell: (row) => {
            return (
                (row.Concepto == null) ? row.identificador : row.Concepto
            )
        }
    },
    {
        name: 'Categoría',  
        width: "200px",
        cell: (row) => {
            return (
            <select id={"sel-categoria-"+row.ID} style={{width: '100%'}} onChange={() => actualizarCategoriaOrdenPago(row.ID)}>
            <option>{row.categoria}</option> 
            {props.categorias.map(item => ( 
             <option>{item.categoria}</option>
            ))}	
            </select>
            )
        }
    },
    {
        name: 'Estado',
        cell: (row) => {
            return (
                <td>
                            {((row.estado == "Generada") && (props.tipo ==  "2")) ? 
                                <select id={"sel-estado-"+row.ID} onChange={() => ActualizarStatus(row.ID, row.ordencompraf)}>
                                <option>{row.estado}</option>
                                <option>Cancelada</option>
                                <option>Pagada</option>
                                </select> : <label>{row.estado}</label>
                            }
                </td>
            )
        }
         
    },
    {
        name: 'Tipo',
        cell: (row) => {
            return (
                <td>
                                <select id={"sel-tipo-"+row.ID} onChange={() => ActualizarTipo(row.ID)}>
                                <option>{row.tipoorden}</option>
                                <option>Anticipo</option>
                                <option>Credito</option>
                                <option>Pago</option>
                                </select>
                </td>
            )
        }  
    },
    {
        name: 'Detalles',
        cell: (row) => {
            return (
                <button type="button" id={row.ID} className='btn btn-outline-success btn-sm' onClick={() => verOrdenPago(row.ID, row.ordencompraf)}>Ver</button>
            )
        } 
        
         
    },
    {
        name: 'Factura',
        cell: (row) => {
            return (
                (row.factura == null) ? 
                    <td>
                     <button type="button" id={row.ID} className='btn btn-outline-success btn-sm'  onClick={() => agregarDoc(row.ID)}>Añadir</button>
                    </td> :
                    <td>
                        <a target="_blank" href={process.env.REACT_APP_API_URL+row.factura}>Ver</a>
                    </td>
                    
            )
        }
    },
    {
        name: 'Eliminar',
        cell: (row) => {
            return (
                <button className='btn btn-outline-danger btn-sm' onClick={ () => desactivarOrdenPago(row.ID) }>Eliminar</button>
            )
        }
    },
    {
        name: '',
        cell: (row) => {
            return (
                <input id="idreq" value={row.idrequisicion} hidden></input>
            )
        }
    },
    {
        name: '',
        cell: (row) => {
            return (
                <input id='input-fact' type='file' style={{display:'none'}} onChange={()=> postFile(row.ID)}hidden></input>
            )
        }
    },
];

 

    return(
        <div className="container">
            <style>{style}</style>

            <Nabvar defaultColor={props.defaultColor} idd="navOrdpago" titulo="Ordenes de pago" departamento={props.rzonsocial} dptoid={props.dptoid}/> 
      
            <div class="row p-3">

                <div>
                    <input id='tipoN' hidden></input>
                    <span>Filtrar por fecha &nbsp; </span>
                    <input id='input-fecha-op' type='date' class="filter-date" onChange={() => getOrdenesPago()}></input>
                    <span>&nbsp; </span>
					<input id='input-fecha-termino' type='date' class="filter-date" onChange={() => getOrdenesPago()}></input>
                	<span>&nbsp; &nbsp; </span>
                    <span>Identificador: &nbsp;&nbsp;</span>
                    <input  id='input-identificador-op' type='text'   style={{width:'300px',fontSize:'12px', cursor:'pointer'}} ></input><span>&nbsp;</span> <span>&nbsp;</span> 
                    <span>&nbsp;</span> 
                    <button class="btn btn-outline-success btn-sm" onClick={() => getOrdenesPago()}>Filtrar <FaSyncAlt/></button>
				</div> 

                <div>
                    <button onClick={openModal2} id="btnOrdpago" class="btn btn-outline-success btn-sm">Nueva Orden de Pago</button>
                    <button onClick={openModal3} id="btnOrdpago" class="btn btn-outline-success btn-sm">Gráfica <FaChartPie/></button>
                </div> 

                <div id="div-Ordpago" class="lista-registros-op">
                    <DataTableExtensions
                        columns={columns}
                        data={ordenesPago}
                        print={true}
                        export={false}
                        filterPlaceholder={"Filtrar"}
                        >
                        <DataTable
                                    columns={columns}
                                    data={ordenesPago}
                                    fixedHeader={true}
                                    fixedHeaderScrollHeight={'100%'}
                                    pagination
                                    customStyles={tableCustomStyles}
                                    highlightOnHover={true}
                                    noDataComponent={"No hay registros para mostrar"}
                        />
                    </DataTableExtensions>
                    <table id="productstable-Ordpago"  class="width-100" hidden> 
                        <tr class="sticky-header">
                            <th>Folio</th>
                            <th>Serie</th>
                            <th>Fecha</th>  
                            <th>Orden</th>
                            <th>Identificador</th>
                            <th>Categoría</th>
                            <th>Estado</th>
                            <th>Detalles</th> 
                            <th>Factura </th> 
                            <th>Eliminar</th>  
                        </tr> 
                        {ordenesPago.map(item => ( 
                        <tr class="font-14 table-row tabletr">
                            <td className='id-orden' >{item.ID}</td>
                            <td className='id-orden' >{item.IDserie}</td>
                            <td><input style={{width: '110px'}} type="date" id={item.ID} onChange={() => actualizarFecha(item.ID)} value={(item.Fecha).substring(0,10)}></input></td>
                            <td>{ item.ordencompraf }</td>
                            <td>{ (item.Concepto == null) ? item.identificador : item.Concepto}</td>
                            <td> <select id={"sel-categoria-"+item.ID} style={{width: '100%'}} onChange={() => actualizarCategoriaOrdenPago(item.ID)}>
                                <option>{item.categoria}</option> 
                                {props.categorias.map(item => ( 
                                 <option>{item.categoria}</option>
                                ))}	
                                </select>
                            </td>

                            <td>{((item.estado == "Generada") && (props.tipo ==  "2")) ? 
                                <select id={"sel-estado-"+item.ID} onChange={() => ActualizarStatus(item.ID,item.ordencompraf)}>
                                <option>{item.estado}</option>
                                <option>Cancelada</option>
                                <option>Pagada</option>
                                </select> : <label>{item.estado}</label>
                            }</td>

                            <td>
                                <button type="button" id={item.ID} className='btn btn-outline-success btn-sm' onClick={() => verOrdenPago(item.ID, item.ordencompraf)}>Ver</button>
                            </td>
                            {(item.factura == null) ? 
                        <td>
                         <button type="button" id={item.ID} className='btn btn-outline-success btn-sm'  onClick={() => agregarDoc(item.ID)}>Añadir</button>
                        </td> :
                        <td>
                            <a target="_blank" href={process.env.REACT_APP_API_URL+item.factura}>Ver</a>
                        </td>
                        }   

                            <td>
								<button className='btn btn-outline-danger btn-sm' onClick={ () => desactivarOrdenPago(item.ID) }>Eliminar</button>
							</td>
                           
                            
                            
                            <td>
                                <input id="idreq" value={item.idrequisicion} hidden></input>
                            </td>
                            <td>
                            <input id='input-fact' type='file' style={{display:'none'}} onChange={()=> postFile(item.ID)}hidden></input>
                            </td>
                        </tr>
                        ))}	 
                    </table> 
                </div>
                
               
                     
                    <div id='ordenpagodiv' >
                    <button id="btnOrdpagoLimpiar" className='btn-clean btn btn-outline-success btn-sm' onClick={cleanForm}>LIMPIAR CAMPOS</button>
               

                    <ProgressBar>
						<ProgressBar animated striped variant="success" label={'Generada'} now={generada} key={1} /> 
						<ProgressBar animated striped variant="success" label={'Surtida'} now={surtida} key={3} /> 
						<ProgressBar animated striped variant="danger" label={'Cancelada'} now={estadocancelada} key={5} />
    				</ProgressBar>
                    <br></br> 
                        <div id='orden1' hidden>
                        <Ordenpago empresaid={props.dptoid} proveedor={proveedor} proveedorSelected={proveedorSelected} filtrarPorProveedor={filtrarPorProveedor} Autorizar={Autorizar} verOrden1={verOrden1} departamento={props.departamento} bancos={props.bancos} tarjetas={props.tarjetas} usuarios={value} logoOP={logoOP}/>
                        </div>
                        
                        <div id='orden2' hidden>
                        <Ordenpago1 empresaid={props.dptoid} proveedor={proveedor} proveedorSelected={proveedorSelected} filtrarPorProveedor={filtrarPorProveedor} Autorizar={Autorizar} verOrden1={verOrden1} departamento={props.departamento} bancos={props.bancos} tarjetas={props.tarjetas} usuarios={value} logoOP={logoOP}/>
                        </div>
                    </div>   
           

            <div id="btns">
                <button id="btn-guardar" className="btn btn-outline-success btn-sm" onClick={actualizarOrdenPago}>Actualizar <FaSyncAlt/></button>
                <button id="btn-imprimir" className="btn btn-outline-success btn-sm" onClick={(e) => Pdf(e)}>Imprimir <FaPrint /></button>
            </div>

            </div>

                        {/* FECHA DE PAGO */}
       <Modal
        isOpen={modalIsOpenPagada}
        onAfterOpen={() => {
            afterOpenModal();
            const today = new Date().toISOString().split('T')[0];
            document.getElementById("fechadepago").value = today;
        }}
        onRequestClose={() => {}}
        //onRequestClose={closeModalPagada}
        style={customStyles}
        contentLabel="Example Modal"
       
      >
          <center><label ref={(_subtitle) => (subtitle = _subtitle)} style={{color:'red', fontSize:'32px'}}>Fecha de pago</label></center>
        <br></br> 
        <input type='date' id='fechadepago' textAlign="right" style={{height:'auto', width:'100%'}}></input>
        <br></br>
        <br></br>
        <center><button  class="btn btn-outline-success btn-sm" onClick={()=> guardarFechaDePago(ordennP)}>&nbsp;Guardar&nbsp;</button></center>
       </Modal>

     <Modal
        isOpen={modalIsOpenGenerar}
        onAfterOpen={afterOpenModalGenerar}
        onRequestClose={closeModalGenerar}
        style={customStyles}
        contentLabel="Example Modal"
       
      >
          <label ref={(_subtitle) => (subtitle = _subtitle)} class="label-1">Seleccionar Opción</label>
        <br></br> 
        <select class="width-100" id="slc-tipo-orden" onChange={mostrarOrden}> 
        <option value='0'>Seleccione</option>
        <option value='1'>ORDEN DE PAGO</option>
        <option value='2'>ORDEN DE PAGO CON TDC EMPRESARIAL</option>
        </select>
        <br></br> 
        <br></br> 
       <div id='nvaorden1' hidden>
                    <NvaOrdenpago XML={XML} proveedor={proveedor} proveedorSelected={proveedorSelected} filtrarPorProveedor={filtrarPorProveedor} rzonsocialEmpresa={rzonsocialEmpresa} departamento={props.departamento} bancos={props.bancos} tarjetas={props.tarjetas} usuarios={value} logoOP={logoOP}/>            
                    <br></br> 
                    <button id="btnS" style={{display:"block"}} class="btn btn-outline-success btn-sm " onClick={() => guardarOrden("1")}>Guardar</button>
        </div>
                    
        <div id='nvaorden2' hidden>
                    <NvaOrdenpago1 XML={XML} proveedor={proveedor} proveedorSelected={proveedorSelected} filtrarPorProveedor={filtrarPorProveedor} rzonsocialEmpresa={rzonsocialEmpresa} departamento={props.departamento} bancos={props.bancos} tarjetas={props.tarjetas} usuarios={value} logoOP={logoOP}/>
                    <br></br> 
                    <button id="btnS" style={{display:"block"}} class="btn btn-outline-success btn-sm " onClick={() => guardarOrden("2")}>Guardar</button>
        </div>
        
       
       </Modal>
       <Modal
        isOpen={modalIsOpenGrafica}
        onAfterOpen={afterOpenModalGenerar}
        onRequestClose={closeModalGrafica}
        style={customStyles}
        contentLabel="Example Modal"
      >
          <label ref={(_subtitle) => (subtitle = _subtitle)} class="label-1"></label>
        <br></br> 
        <div>
                    <span>Filtrar por fecha &nbsp; </span>
                    <input id='input-fecha-op-c' type='date' class="input-date filter-date"  onChange={() => getOrdenesPagoCategoria()}></input>
                    <span>&nbsp; </span>
					<input id='input-fecha-termino-c' type='date' class="input-date filter-date"  onChange={() => getOrdenesPagoCategoria()}></input>
                	<span>&nbsp; &nbsp; </span>
					<button class="btn btn-outline-success btn-sm" onClick={() => getOrdenesPagoCategoria()}>Filtrar <FaSyncAlt/></button>
                    
        </div>
        <div class="div-section-row">
        <div style={{width:'25%'}}>
        <br></br>  <br></br> 
        <table >
            <tr>
                <th>Categoría</th>
                <th>Total</th>
            </tr>
            <tr></tr>
            {ordenesPagoCategoria.map(item => ( 
                    <tr class="font-14"> 
                        <td>{item.categoria}</td>
                        <td>{formatNumber(item.cantidad)}</td>
                     </tr>
                    ))}	
                    <tr> 
                        <td >Total:</td>
                        <td >{formatNumber(totales)}</td>
                    </tr>
 </table>
 </div>
 <div style={{width:'75%'}}>
                    <Chart
                
                chartType="PieChart" 
                width="100%"   
                height={"600px"}  
                options={options}
                data={chartData} 
            /> 
            </div> 
            </div>
       
        
       
       </Modal>

            <Modal
                isOpen={modalIsOpenArchivo}
                onAfterOpen={afterOpenModalA}
                onRequestClose={closeModalA}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <h2 ref={(_subtitle) => (subtitle = _subtitle)} style={{color:'black'}}>Agregar Archivo</h2> 
                <div>Archivo:</div>
                <input id="documento" type="file" style={{display: "none"}}></input>
                <input type="button"  style={{width:'100%' }} id="documento" class="btn btn-outline-success btn-sm" value="Elegir archivo" onClick={() => {document.getElementById('documento').click()}}></input>
                <tr > 
                    <th>Folio</th> 
                    <th>Documento</th> 
                </tr>
                { listadocumentos.map(item => ( 
                                <tr id="tabletr" style={{  fontSize:'13.5px', border: '2px solid #ABB2B9'}}>
                                    <td  align='center' className='id-orden'>{item.folio}</td> 
                                    <td  align='center' className='id-orden'><a target="_blank" rel="noreferrer" href={process.env.REACT_APP_API_URL+item.documento}>{item.documento}</a></td>
                                </tr> 
                                ))}	 
                <br></br>
                <br></br>
                <button onClick={closeModalA} class="btn btn-outline-danger btn-sm " style={{ height:'45px'}}>Cancelar</button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <button onClick={() => addDocumento()} class="btn btn-outline-success btn-sm"  style={{ height:'45px'}}>Guardar</button>
            </Modal>  

            <ModalCarga modalIsOpenLoad={modalIsOpenLoad} defaultColor={props.defaultColor} closeModalLoad={closeModalLoad}/>    
 		
            <Toast/>    
    
        </div>
    )
}