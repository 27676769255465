import React, { useState, useEffect } from 'react' 
import  {FaTools} from 'react-icons/fa'
import { push as Menu } from 'react-burger-menu'
import axios from "axios";

import { ToastContainer, toast } from 'react-toastify';
export default function OpcionesMenu(props) {
 
    const [isMenuOpen1, SetIsMenuOpen1] = useState(props.isMenuOpen1); 
 
	const [dptos, setDeptos] = useState([]);  
	 
 
    function Seleccionar(elemento){ 
        if(isMenuOpen1 == true){ 
            SetIsMenuOpen1(false); 
        }  
        props.unmount(elemento);   
    }

    function notify(message){
		toast(message);
	}
    function logOut() { 
        //props.unmountMe(); 
        if (window.Android){
            window.Android.showToast("", "", "", "0");
            window.location.reload();
            //alert(res);
        }else{
            window.location.reload();
        }
       
    }

    useEffect(()=> {
        if(props.razon != undefined){
         //   alert(props.razon);
            document.getElementById("select").value = props.razon;
        }
		getRazonesSociales(); 
	}, [])


    async function getRazonesSociales(){
		var id = "getDepartamentos"; 
		const rese = await axios.get(process.env.REACT_APP_API_URL+'?id='+id);  
		setDeptos(rese.data);  
        document.getElementById("select").value = props.razon;  
	}
 
    
    const isFound = props.modulos.some(element => {
        console.log(element);
        if (element.nombre == "Solicitudes") {
          return true;
        }
    
        return false;
      });

      const isFoundEgresos = props.modulos.some(element => {
        console.log(element);
        if (element.nombre == "Egresos") {
          return true;
        }
    
        return false;
      });
    

    async function CambiarRzon(){ 
		//e.preventDefault();  
		var select = document.getElementById("select").value;
		var id = "CambiarRzon";  
		let fd = new FormData()  
		fd.append("id", id); 
		fd.append("select", select);  
		fd.append("userid", props.userid);  
		const res = await axios.post(process.env.REACT_APP_API_URL, fd);
        console.log(res.data[0]); 
		 
		if(res.data[0].res == "1"){  
			//setAcceso(false); 
			/*setUsuario(res.data[0].usuario); 
			setUserid(res.data[0].userid);
			setName(res.data[0].name);
			setDepartamento(res.data[0].departamento);
			setTipo(res.data[0].tipo);
			setRzonsocial(res.data[0].rzonsocial); */
			props.unmountMe(res.data[0].rzonsocial, res.data[0].dptoid);   
		}else{
            notify("No tiene acceso a esta razon social");
        }
		
	}
    
 
    var isMenuOpen = function(state) { 
  
        SetIsMenuOpen1(state.isOpen); 
        return state.isOpen; 
      };
   
 
    return (
<>
    
<Menu left isOpen={ isMenuOpen1 } onStateChange={ isMenuOpen }

>
        
      
        <div id="sidepanel" style={{width:'100%', backgroundColor:props.defaultColor}}>
                {/*<img id="sidepanel-logo" alt="Logo" /><br></br>*/}
                {<img  style={{width:'150px' , marginLeft:'25%'}} src={process.env.REACT_APP_API_URL_DEFAULT+props.defaultLogo}   />
                    }
                     
                <span id="top-menu" >
                      <br></br>  
                       
                      <select style={{maxWidth:'100%', fontWeight:'bold', color:'white', backgroundColor:props.defaultColor, border:'none', textAlignLast:'center'}} id="select" onChange={()=> CambiarRzon()}>
                            { 
                                dptos.map(item => ( 
                                    <option value={item.name}> {item.name}</option>
                                ))
                            }  
                        </select>   
                        <br></br>   
                    <b>Bienvenido:</b> {props.name} 
                    <br></br>
                    <b>Departamento:</b> {props.departamento}
                    <br></br>
                   
                    
                    <span onClick={() => { Seleccionar("5"); }} style={{ cursor: 'pointer' }}><b>Cambiar contraseña</b></span>
                    {(props.tipo == "2") ?
                    <><br></br><FaTools onClick={()=> {Seleccionar("9")}}/> </>
                    :<></>
                    }
                </span> 
                <hr></hr>
                <div id="sidebtn" onClick={() => { Seleccionar("3"); }} hidden>
                    <img id="sideimg3" alt="" />
                    <span>Nueva Requisición</span>
                </div>
                <div id="sidebtn" onClick={() => { Seleccionar("2"); }}>
                    <img id="sideimg10" alt="" />
                    <span>Requisiciones</span>
                </div>

                <div id="sidebtn" onClick={() => { Seleccionar("4"); }}>
                    <img id="sideimg9" alt="" />
                    <span>Ordenes de compra</span>
                </div>
                
                { 
                 
                    (props.modulos != null && (isFound)) ? 
                        <div id="sidebtn" onClick={() => { Seleccionar("11"); }}>
                            <img id="sideimg91" alt="" />
                            <span>Solicitudes de pago</span>
                        </div>
                    : 
                        <></> 
                 }
               
                

                
                {(props.tipo == "2") ?
                   <div id="sidebtn" onClick={() => { Seleccionar("6"); }}>
                  <img id="sideimg4" alt="" />
                  <span>Ordenes de pago</span>
                  </div>
                    :
                    <></>}
                 
                {(props.tipo == "2") ?
                    <div id="sidebtn" onClick={() => { Seleccionar("1"); }}>
                        <img id="sideimg1" src="/SistemaGas/resources/request_page_white_24dp.svg" alt="" />
                        <span>Usuarios</span>
                    </div>
                    :
                    <></>}

                    {(props.tipo == "2") ?
                    <div id="sidebtn" onClick={() => { Seleccionar("10"); }} style={{color:'white'}}>
                        <img id="sideimg11"  alt="" style={{fill: 'white'}} />
                        <span>Proveedores</span>
                    </div>
                    :
                    <div id="sidebtn" onClick={() => { Seleccionar("10"); }} style={{color:'white'}}>
                        <img id="sideimg11"  alt="" style={{fill: 'white'}} />
                        <span>Proveedores</span>
                    </div>}
                <div id="sidebtn" onClick={() => { Seleccionar("7"); }} hidden>
                    <img id="sideimg4" src="/SistemaGas/resources/request_page_white_24dp.svg" alt="" />
                    <span>Cambiar Razón Social</span>
                </div>
                {(props.tipo == "2") ?
                    <div id="sidebtn" onClick={() => { Seleccionar("8"); }}>
                    <img id="sideimg9" alt="" />
                    <span>Reporte de compra</span>
                </div> 
                    :
                    <></>}
                    



                    {
                        
                 
                        (props.modulos != null && (isFoundEgresos)) ?  
                            <div id="sidebtn" onClick={() => { Seleccionar("12"); }}>
                                <img id="sideimg12" alt="" />
                                <span>Egresos</span>
                            </div> 
                        :
                            <></>
                    }
                    

               
                
                <div id="sidebtn" onClick={() => logOut()}>
                    <img id="sideimg5" alt="" />
                    <span>Cerrar sesión</span>
                </div>

            </div>
            
      
      </Menu>      
      <ToastContainer 
      progressClassName="toastProgress"
      position="top-center"
      />
      
      </>
       
        
    )
   
}
